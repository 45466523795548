//Example of data expected: 
//const hostURL = 'http://localhost:8800/api/upload';
//Here data come from Add.jsx component


// const hostUrl = '/upload';
export const HandleUpload = async (selectedFile, hostUrl, userId, prefix, catId) => {
    if (!selectedFile) {
        alert("Please select a file");
        return;
    };
    // console.log('userId from HandleUpload.js', userId);
    
    const formData = new FormData()
    formData.append('file', selectedFile, selectedFile.name);
    // const newUserId = userId.toString()
    formData.append('userId', userId);
    formData.append('catId', catId);
    formData.append('prefix', prefix);
    // console.log('other from HandleUpload.js', other);
    // console.log('formData.get(userId) from HandleUpload', parseInt(formData.get('userId')))
    //The formData.get() method returns a string value, even if the original value passed to it was a number. 

    let res = await fetch(hostUrl, {
        method: 'POST',
        body: formData
    });

    const data = await res.json();
    // console.log('HandleUpload data =',data);
    // setUploaded(data);// not necessery to store it
    return data;


}