
export const INITIAL_STATE = {
    values: {
        id: null,
        num: '',
        issued_by: '',
        pedigree_img_url: '',
        cat_systems_id: '',
        catId: null
    },
    catSystems: [],
    currentSystem: '',
    err: "",
    selectedFile: null,
    filePa: null,
    isImageSaved: ""
}

export const formReducer = (state, action) => {
    switch (action.type) {
        case "CATID":
            return {
                ...state,
                values: {
                    ...state.values,
                    catId: action.payload
                }
            };
        case "FETCHED_CAT_SYSTEMS":
            return {
                ...state,
                catSystems: action.payload,
            };
        case "CHANGE_INPUT":
            return {
                ...state,
                values: {
                    ...state.values,
                    [action.payload.name]: action.payload.value
                }
            };
        case "CHANGE_SELECT_SYSTEM":
            return {
                ...state,
                currentSystem: action.payload,
                values: {
                    ...state.values,
                    cat_systems_id: action.payload.value,
                }
            };
        case "CHANGE_FILE":
            return {
                ...state,
                selectedFile: action.payload,
                err: "",
            };
        case "SET_FILE_URL":
            return {
                ...state,
                filePa: action.payload,
                values: {
                    ...state.values,
                    pedigree_img_url: action.payload,
                },
            };
        case "ERROR_RESET":
            return {
                ...state,
                err: "",
            };
        case "ERROR":
            return {
                ...state,
                err: action.payload,
            }
        default:
            return state;
    }
}

//Was before with useState:
  // const [values, setValues] = useState(
  //   {
  //     // id: null,
  //     num: '',
  //     issued_by: '',
  //     pedigree_img_url: '',
  //     cat_systems_id: '',
  //     catId
  //   }
  // );

  // const [isCreating, setIsCreating] = useState(true); // determine if we're creating a new entity or updating an existing one
  // const [selectError, setSelectError] = useState(false);
  // const [currentSystem, setCurrentSystem] = useState('')
  // const [err, setError] = useState(null);
  // const [catSystems, setCatSystems] = useState([]);
  // const [selectedFile, setSelectedFile] = useState(null);