// OrderForm.js
import { LanguageContext } from "../../context/langContext";
import React, { useContext, useEffect, useState } from 'react';
import { faMarsAndVenus } from "@fortawesome/free-solid-svg-icons";


import './order.scss';
import CatCardSell from "../../components/CatCardSell/CatCardSell";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { sendOrder } from "../../services/api";
import { useAuth } from "../../context/authContext";

const Order = () => {
    const { translations } = useContext(LanguageContext)
    const [comment, setComment] = useState('');
    const [delivery, setDelivery] = useState(false);

    // const location = useLocation();
    // const auth = useAuth();
    // const currentUserBuyerId = auth.currentUser.id;
    // const [currentUserBuyerEmail, setBuyerEmail] = useState('');
    // const [currentUserBuyerFirstName, setCurrentUserBuyerFirstName] = useState('');

    useEffect(() => {
        const storedUser = localStorage.getItem('userKey');
        if (storedUser) {
            // const userObject = JSON.parse(storedUser);
            // setBuyerEmail(userObject.email);
            // setCurrentUserBuyerFirstName(userObject.first_name)
        } else {
            console.log('No user found in localStorage');
        }
    }, []);
    //data about cat we get from catcardSale as a prop/state
    // const saleCat = location.state?.saleCat; // Access the passed state from CarCardSell function
    // console.log('saleCat from Order =', saleCat);
   
    //we send currentUserId (on backend we will fetch his email, name, phone number), info about cat, info about order
    // const handleOrder = async (withBreeding) => {
    //     const orderDetails = {
    //         currentUserBuyerId,
    //         currentUserBuyerEmail,
    //         currentUserBuyerFirstName,
    //         catId: saleCat.cat_id,
    //         breederId: saleCat.breeder_id,
    //         comment,
    //         delivery,
    //         price: withBreeding ? saleCat.price_breeding : saleCat.price_pet,
    //         currencyId: saleCat.currency_id,
    //         saleInformationId: saleCat.sale_information_id
    //     };
    //     console.log('orderDetails', orderDetails)
    //     try {
    //         const response = await sendOrder(orderDetails);
    //         console.log('response', response);
    //     } catch (error) {
    //         console.error('There has been a problem with your order operation:', error.message);
    //     }       
    // };

    return (
        <div className="order__container">
            <div className="form__container">
                <form className="order__form">
                    <div>
                        {translations.order.sent_text}
                    </div>
                    {/* <div>
                        <p className="order__p" htmlFor="name">{translations.order.comment}</p>
                        <textarea
                            id="comment"
                            name="comment"
                            rows="5"
                            cols="40"
                            value={comment}
                            onChange={(e) => setComment(e.target.value)} />
                    </div> */}
                    {/* <div>
                        <input
                            type="checkbox"
                            id="delivery"
                            name="delivery"
                            checked={delivery}
                            onChange={(e) => setDelivery(e.target.checked)}
                        />
                        <label htmlFor="email">{translations.order.delivery}</label>
                    </div> */}
                </form>
                {/* <button className='order__button' onClick={() => handleOrder(false)}>
                    <div className="order__button_text">{translations.order.submit_order}</div>
                    <div className="order__button_price">{saleCat.price_pet} {saleCat.currency_abb}</div>
                </button> */}
                {/* <button className='order__button' onClick={() => handleOrder(true)}>
                    <div className="order__button_text">{translations.order.submit_order_with_breeding}</div>
                    <div className="order__button_price"><FontAwesomeIcon className="icon__mars-and-venus" icon={faMarsAndVenus} />{saleCat.price_breeding} {saleCat.currency_abb}</div>
                </button> */}
            </div>
            {/* {saleCat && <CatCardSell saleCat={saleCat} />} */}
        </div>
    );
};

export default Order