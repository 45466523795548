import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import FormInput from '../../components/FormInput/FormInput';
import "./createMetric.scss"
import { useAuth } from '../../context/authContext';
import { formatValuesForSelect } from '../../utils/formatValuesForSelect';
import { UploadFile } from '../../components/UploadFile/UploadFile'
import { HandleUpload } from '../../components/UploadFile/HandleUpload';
import { fetchCatSys, postMetric } from '../../services/api';

///// Component EditMetric can add a new metric or edit an existing metric /////

const CreateMetric = () => {
  const BASE_URL = process.env.REACT_APP_API_URL; //need only for UploadFile - latter maybe better to refactor

  const navigate = useNavigate();
  const auth = useAuth();
  const location = useLocation();
  const catId = location.pathname.split("/")[2] //http://localhost:3001/post/1 - we take a number from URL address in browser
  // We need to get metric values. We get from cat table metric_id and JOIN all metric data.
  const currentUserId = auth.currentUser.id;

  const [values, setValues] = useState(
    {
      metric_num: '',
      issued_by: '',
      metric_img_url: '',
      cat_systems_id: '',
      cat_system: '',
      catId
    }
  );

  const [error, setError] = useState(null);
  const [catSystems, setCatSystems] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isSubmissionSuccessful, setSubmissionSuccessful] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const fetchCatSystems = async () => {
      try {
        const res = await fetchCatSys();
        const dataforSelect = formatValuesForSelect(res, 'name', 'abb');
        setCatSystems(dataforSelect); //better like this setCatSystems(dataforSelect) - without callback arraw function inside
      } catch (error) {
        console.log(error);
      }
    }
    fetchCatSystems()
  }, [])

  const handleChangeFile = (file) => {
    setSelectedFile(file)
    setError(null)
  }

  //UPLOAD FILE to SERVER to folers uploads/user_id/file-name. 'metric' here is the prefix which isert into file name before saving it.
  //we need an async here (to use await)

  const uploadCattery = async () => {
    try {
      const fileData = await HandleUpload(selectedFile, `${BASE_URL}/upload`, currentUserId, 'metric', catId);
      setValues((prev) => ({ ...prev, metric_img_url: fileData.filePath }));
    } catch (err) {
      setError('File upload failed.');
    }
  };

  // ONLY WHEN WE HAVE A IMG_URL in our state we send all values/state to server
  useEffect(() => {
    const final = async () => {
      // console.log('final evoked')
      if (values.metric_img_url) {
        // Check if we have an URL image in our values (if setValues((prev) => ({ ...prev, metric_img_url: fileP.filePath }))) exacuted
        // console.log('if true')
        console.log('values from final', values);
        try {
          //creating a new entity (sending post requewst to server to save in DB)
          const response = await postMetric(values) // my API URL //in package.json we write "proxy": "http://localhost:8801/api/"
          // console.log('New entity created:', response.data);
          if (response.data) setSubmissionSuccessful(true)

        } catch (err) {
          console.log('Error submiting form:', err); setError(err.response.data)
        } finally {
          setIsSubmitting(false);
        }
      }
    }
    if (isSubmitting) {
      final();
    }
  }, [isSubmitting, values.metric_img_url, values]);

  // if (isSubmissionSuccessful) {
  //   navigate(`/cats/${catId}`);
  // }

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   if (values.cat_systems_id) {
  //     // console.log('values.cat_system_id', values.cat_systems_id)
  //     if (selectedFile) {
  //       uploadCattery()
  //     } else setError("Choose a file")
  //   } else setError("Choose a system")
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    if (!values.cat_system) {
      setError('Please select a system.');
      return;
    }

    if (!selectedFile) {
      setError('Please choose a file.');
      return;
    }

    setIsSubmitting(true);

    await uploadCattery();
  };

  // Redirect on successful submission
  useEffect(() => {
    if (isSubmissionSuccessful) {
      navigate(`/cats/${catId}`);
    }
  }, [isSubmissionSuccessful, navigate, catId]);

  const handleChange = (e) => {
    // console.log('CreateMetric.jsx | e.target = ', e.target)
    setValues({ ...values, [e.target.name]: e.target.value })
  };

  const handleSystemChange = (selectedOption) => {
    console.log('selectedOption', selectedOption)
    setValues((prev) => ({ ...prev, cat_system: selectedOption, cat_systems_id: selectedOption.value }));
  }

  const inputs = [
    {
      id: "metric_num",
      name: "metric_num",
      type: "text",
      placeholder: "Metric number",
      errorMessage: "Please, write a number, it should be 3-50 symbols",
      label: "Metric number*",
      pattern: "^[А-Яа-яA-Za-z0-9 ]{3,50}$", //This pattern allows for any uppercase or lowercase letters, digits, and a single space, with a minimum length of 3 and a maximum length of 50
      required: true,
      value: values.metric_num,
      maxLength: 50, // limit to 50 characters
      onChange: handleChange
    },
    {
      id: "issued_by",
      name: "issued_by",
      type: "text",
      placeholder: "Issued by",
      label: "Metric was issued by (Club)*",
      maxLength: 50, // limit to 50 characters
      errorMessage: "Please, write an organization which issued a document, it should be 3-50 symbols",
      pattern: "^[А-Яа-яA-Za-z0-9 ]{3,50}$", //This pattern allows for any uppercase or lowercase letters, digits, and a single space, with a minimum length of 3 and a maximum length of 50
      required: false,
      value: values.issued_by,
      onChange: handleChange
      // className:"joinInput"
    },
    {
      id: "cat_system",
      name: "cat_system",
      type: "select", // Change the type to 'textarea'
      placeholder: "System issued a metric",
      errorMessage: "System can't be empty",
      label: "Metric was issued according system*",
      required: true,
      options: catSystems,
      value: values.cat_system,
      maxLength: 100, // limit to 100 characters
      onChange: handleSystemChange
    },
  ]
  const cancelEdit = () => {
    navigate(`/cats/${catId}`)
  }

  return (
    <div className="edit-metric">
      <form className="cattery-form" onSubmit={handleSubmit}>
        <h1>Metric - creating </h1>
        <p>Please, fill all fields</p>
        {values && (
          inputs.map((input) => (
            //valueSelect send to FormInput value as props
            <FormInput
              key={input.id}
              {...input}
              name={input.name}
              placeholder={input.placeholder}
              required={input.required}
              options={catSystems}
              onChangeSelect={input.onChange}
              value={input.value}
              onChange={input.onChange}
            />))
        )}
        {/* it's rendered if we don't have any metric in our values */}
        {/* {values && !values[0] && (
          inputs.map((input) => (
            <FormInput key={input.id} options={catSystems} {...input} onChangeSelect={onChangeSystem} value={values[input.name] || ''} onChange={onChange} />))
          )} */}
        {/* 
        <div className="cattery-selectInput">
        <label className="selectInput__label">Your cattery system (on your certificate)*</label>
        <Select className="selectInput__input" options={catSystems} onChange={onChangeSystem} value={currentSystem} />
      </div> */}
        {/* {console.log('values from EditMEtric from return', values)}*/}
        {/* DOESN'T WORK - I don't see IMAGE - don't know why */}
        {values.metric_img_url &&
          <figure className='metric-img'>
            <div>
              <figcaption>Current image in database*</figcaption>
              <img src={`../../${values.metric_img_url}`} alt="Cat-metric" />
            </div>
          </figure>
        }
        <UploadFile selectedFile={selectedFile} onChangeFile={handleChangeFile} />
        <button type='submit' className='joinButton'>Send</button>
        <button type='button' className='button-light' onClick={cancelEdit}>Cancel</button>
        {error && <div className='errorMessage'> {error}</div>}
      </form>
    </div>
  )

}

export default CreateMetric
