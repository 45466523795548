import { useEffect, useState } from 'react'
import { fetchFather, fetchMother, fetchParents } from '../../services/api';
import { useParams } from 'react-router-dom';

const initialState = {
    mother: {},
    father: {}
};
const useParents = () => {
    const catId = useParams().id;
    // console.log('useParents evoked! catId = ', catId)

    const [parents, setState] = useState(initialState);
    // const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        let isMounted = true;
        // console.log('useParents useEffect catId = ', catId)

        const fetchCatRelatedData = async () => {

            try {
                const resDataParents = await fetchParents(catId); //cat_id, father_id, id, mother_id
                // console.log('useParents useEffect, resDataParents', resDataParents);
                if (resDataParents && resDataParents[0]) {
                    const { mother_id, father_id } = resDataParents[0];
                    const fetchTasks = [];

                    if (mother_id) {
                        fetchTasks.push(fetchMother(mother_id)); //fetchMother from cat table
                    }
                    if (father_id) {
                        fetchTasks.push(fetchFather(father_id)); //fethcFather from cat table
                    }

                    const results = await Promise.all(fetchTasks);
                    if (results) {
                        // console.log('useParents.jsx results =', results)
                        // motherEmsBreedResult = await fetchBreedById(results[0][0].breed_id);
                        // motherColorRes = await fetchColor(mother_id);
                        // console.log('motherColor', motherColorRes.data);
                        // console.log('motherEmsBreedResult = ', motherEmsBreedResult)
                        // fatherEmsBreedResult = await fetchBreedById(results[1][0].breed_id);
                        // fatherColorRes = await fetchColor(father_id);
                        // console.log('fatherEmsBreedResult = ', fatherEmsBreedResult)
                    }
                    const updatedState = { ...initialState };
                    // Also, when using the Promise.all() with dynamic tasks, you should be careful about how you destructure results.
                    // If you're not always sure about the order or presence of results 
                    // (e.g., if mother_id doesn't exist but father_id does), then destructuring like[resMother, resFather] can be problematic.
                    // Instead of destructuring, you can access results based on the order of tasks:
                    let index = 0;
                    if (mother_id) {
                        updatedState.mother = results[index++][0] || {}; // access the first result
                        // updatedState.mother.emsBreed = motherEmsBreedResult[0].ems || {};
                        // updatedState.mother.color = motherColorRes.data || {};
                        // results.shift(); // remove the first result
                    }

                    if (father_id) {
                        updatedState.father = results[index][0] || {};
                        // updatedState.father.emsBreed = fatherEmsBreedResult[0].ems || {};
                        // updatedState.father.color = fatherColorRes.data || {}; 

                    } if (isMounted) {
                        setState(updatedState);

                    }
                    // const [resMother, resFather] = await Promise.all(fetchTasks);
                    // console.log('useParents.jsx parents = ', parents)
                    // updatedState.mother = mother_id ? resMother[0] : {};
                    // updatedState.father = father_id ? resFather[0] : {};
                    // setState(prevState => ({ ...prevState, ...updatedState }));
                } else {
                    setState(initialState);
                }
            } catch (error) {
                console.log(error)
                if (isMounted) {
                    setState(initialState)
                }
            }
        }
        if (catId) fetchCatRelatedData();

        const cleanUp = () => {
            // console.log('clean up function');
            isMounted = false;
        }
        return cleanUp;

    }, [catId])


    // console.log('useParents final parents = ', parents)
    return parents;
}

export default useParents