import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react'
// import { Link } from 'react-router-dom';
import { Clue } from '../../../components/ui/Clue/Clue';
import { CatContext } from '../../../context/CatContext';
// import catAva from '../../img/catAva.jpg';
import { faMars, faVenus } from "@fortawesome/free-solid-svg-icons";
import './catProfileUp.scss';
import { DropdownMenu } from '../../../components/ui/DropdownMenu';
import { LanguageContext } from '../../../context/langContext';

//     const catProfileValue = {
//     metric,
//     pedigree,
//     // catId,
//     applicationStatus: state.applicationStatus,
//     prevOwner: state.prevOwner, handleDelete, handleMove,
// }
const CatProfileUp = (props) => {
    const { catProfileValue } = props;
    const prevOwner = catProfileValue?.prevOwner;
    const handleDelete = catProfileValue?.handleDelete;
    const handleMove = catProfileValue?.handleMove;
    // console.log('CatProfileUp | catProfileValue = ', catProfileValue)
    const { translations, language } = useContext(LanguageContext)
    const { data } = useContext(CatContext);
    const catAva = '/images/catAva.jpg'
    // console.log('CatProfileUp | handleDelete = ', handleDelete)
    // console.log('CatProfileUp | data = ', data)
    const cat = data
    // console.log('cat.is_my_cat = ', cat.is_my_cat);
    if (!cat) {
        return null; // Return null or a fallback UI if cat is not available
    }
    return (
        <div className="cat-card">
            <div className='cat-card__left'>
                <div className='catAva'>
                    <div className="catAva_relative">
                        <img className='catAva__img'
                            src={cat.cat_ava_url ? cat.cat_ava_url : catAva}
                            alt={cat.cat_ava_url ? ('Photo of ' + cat.cat_name) : ('catAva')} />

                        <div className="icon-mars-wrap">
                            {cat.sex === "male" ? (
                                <FontAwesomeIcon icon={faMars} className="icon-mars" />
                            ) : (
                                <FontAwesomeIcon icon={faVenus} className="icon-venus" />
                            )}
                        </div>
                    </div>
                </div>

            </div>
            <div className='cat-profile__right'>
                <div className='cat-profile__right-title'>
                    <b>{cat?.cat_name_cattery_prefix} {cat?.cat_name} {cat?.cat_name_suffix}</b>
                </div>
                <div className='text'><div className='breed-highlight'>{language === 'en' ? cat?.breed_en : cat?.breed_ru} - {cat?.ems}</div></div>
                <div className='text'><span>{translations.online_owner} </span>{cat?.first_name} {cat?.last_name}
                    <span><Clue text={'* owner not confirmed'} /></span>
                </div>
                <div className='text'><span>{translations.breeder} </span>
                    {cat?.breeder_id ? (
                        <>
                            {cat?.breeder_first_name} {cat?.breeder_last_name}
                        </>
                    ) : (
                        <>
                            {cat?.breeder_not_user}
                        </>
                    )
                    }
                </div>
                <div className='text'><span>{translations.birthday} </span>{cat.cat_birthdate}</div>
                <div className='text'>
                    <span>{translations.color_ems}</span>
                    {cat?.colorFullEms && cat?.colorFullEms}
                    <div className='text'>
                        <span>{translations.color} </span>
                        {cat.main_eng_short && ` ${cat.main_eng_short}`}
                        {cat.gold && ` ${cat.gold?.toLowerCase()}`}
                        {cat.white && ` ${cat.white?.toLowerCase()}`}
                        {cat.tabby && ` ${cat.tabby?.toLowerCase()}`}
                        {cat.point && ` ${cat.point?.toLowerCase()}`}
                        {cat.eye && ` with ${cat.eye} eyes.`}
                    </div>
                </div>
                <div className='text'>{cat.description}</div>
            </div>
            <div className="cat-profile_others">
                {cat.is_my_cat === 1 && <div className='cat-profile__mycat'>My cat</div>}
                {/* {owners && <div className='cat-profile__registered'>Owner Reg</div>} */}
                {cat.registered !== 0 && <div className='cat-profile__registered'>Ped Reg</div>}
                {cat.sex === 'female' && (
                    cat.registered !== 0 ?
                        (<div
                            className='cat-profile_others__clue'
                            data-clue='Registered female cat can add mates and kittens. Register your cat!'>
                            <button className="button-light">{translations.add_mate}</button>
                        </div>
                        ) : (
                            <div
                                className='cat-profile_others__clue'
                                data-clue='Registered female cat can add mates and kittens. Register your cat!'>
                                <button className="button-light" disabled>{translations.add_mate}</button>
                                {/* <span><Clue text={'Registered female cat can add mates and kittens. Register your cat!'} /></span> */}
                            </div>
                        )
                )}
                <div className='card-id'>ID: {cat.id}</div>
                <>
                    <DropdownMenu
                        catId={cat.id}
                        which={'cat'}
                        link1="Add a photo"
                        linkUpdate="Update"
                        linkDelete="Delete"
                        cat={cat}
                        handleMove={handleMove}
                        handleDelete={handleDelete}
                        prevOwner={prevOwner}
                    />
                </>
            </div>

            {/* {
                cat.registered === 0 ? (

                    <div className='button-container'>
                        {prevOwner.length >= 1 ?
                            (<button className="update" onClick={handleMove}>I'm not an owner. Delete it from my page. Move to a previous owner.</button>
                            ) : (
                                <button className="delete" onClick={handleDelete}>Delete</button>
                            )}
                        <Link to={`/cats/${cat.id}/update`}><button className="update">Update</button></Link>
                    </div>
                ) : (
                    <Link to={`/cats/${cat.id}/updateshort`}><button className="update">Update</button></Link>
                )

            }
            we don't show delite and update button when cat is registered !!! */}
        </div >
    )
}

export default CatProfileUp
