import React, { useState, useEffect } from 'react'
import { UploadFile } from '../../components/UploadFile/UploadFile';
import { HandleUpload } from '../../components/UploadFile/HandleUpload';
import { useLocation, useNavigate } from 'react-router-dom';
import './addPhoto.scss';
import { useAuth } from '../../context/authContext';
import { fetchOneCat, postCatAva } from '../../services/api';

const AddPhoto = () => {
    const auth = useAuth();
    const [cat, setCat] = useState({});
    const [selectedFile, setSelectedFile] = useState(null);

    const location = useLocation()
    const navigate = useNavigate()
    const catId = location.pathname.split("/")[2] //http://localhost:3001/post/1 - we take post number from URL address in browser
    const currentUserId = auth.currentUser.id;
    const BASE_URL = process.env.REACT_APP_API_URL; //need only for UploadFile - latter maybe better to refactor


    useEffect(() => {
        const fetchData = async () => {
            try {
                const resData = await fetchOneCat(catId);
                setCat(resData)
            }
            catch (err) { console.log(err) }
        }
        fetchData();
    }, [catId])

    const handleOnChangeFile = (file) => {
        setSelectedFile(file);
    }

    const handleSubmit = async (e) => {

        e.preventDefault();

        const fileP = await HandleUpload(selectedFile, `${BASE_URL}/upload`, currentUserId, 'cat-photo', catId); //get selectedFile, hostUrl, userId, prefix

        if (selectedFile) {
            try {
                const response = await postCatAva(catId, fileP)
                console.log(response.data);
                navigate(`/cats/${catId}`);
            } catch (error) {
                console.log('No file', error);
            }
        }
    }

    return (
        <div className='page-container'>
            <div className="add-photo">
                <h2>Add a photo to {cat.cat_name}'s page.</h2>
                <UploadFile selectedFile={selectedFile} onChangeFile={handleOnChangeFile} />
                <button onClick={handleSubmit}>Save the photo</button>
            </div>
        </div>
    )
}

export default AddPhoto

