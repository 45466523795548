
export const formatValuesForSelect = (resData, whatToFormat, whatToFormat2) => {
  return resData.map(item => {
    let { id: value, [whatToFormat]: label, [whatToFormat2]: extra } = item;

    // If country_en is the property to format, update the value to country_id
    if (whatToFormat === 'country_en') {
      value = item.country_id;
    }

    // Concatenate extra information if applicable
    if (whatToFormat2 === 'ems' || whatToFormat2 === 'abb') {
      label = `${extra} - ${label}`;
    }

    return { value, label };
  });
};


// export const formatValuesForSelect = (resData, whatToFormat, whatToFormat2) => {

//   let formattedArray = [];

//   for (let i = 0; i < resData.length; i++) {
//     let obj = {};
//     console.log('whatToFormat2', whatToFormat2);
//     console.log('whatToFormat', whatToFormat);
//     let { id: value, [whatToFormat]: label, [whatToFormat2]: extra } = resData[i];
//     if (whatToFormat === 'country_en') {
//       let { country_id: value, [whatToFormat]: label, [whatToFormat2]: extra } = resData[i];
//       obj.value = value;
//       obj.label = label;
//       formattedArray.push(obj);
//     }
//     if (whatToFormat2 === 'ems') label = `${extra} - ${label}`;
//     if (whatToFormat2 === 'abb') label = `${extra} - ${label}`;
//     // label = `${extra} - ${label}`
//     obj.value = value;
//     obj.label = label;
//     formattedArray.push(obj);
//   }
//   // console.log('formattedArray', formattedArray)
//   return formattedArray;
// }