import React, { useContext, useState } from 'react';
import { useAuth } from '../../context/authContext.js';
import { faMars, faVenus, faHeart, faLocationDot, faMarsAndVenus, faCopyright, faUser, faCakeCandles, faVenusMars, faEye, faPalette, faTruck, faHouse } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LanguageContext } from "../../context/langContext";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import './catProfileSell.scss';
import { CatContext, CatContextProvider } from '../../context/CatContext';
import { SaleInformationOneCatContext, SaleInformationOneCatProvider } from '../../context/SaleInformationOneCatContext.js';

// const CatProfileSell = () => {
//     return (
//         <SaleInformationOneCatProvider>
//             <CatProfileSellChild />
//         </SaleInformationOneCatProvider>
//     );
// }
const CatProfileSell = () => {
    // const getCatIdFromCurrentUrl = () => {
    const urlParts = window.location.pathname.split('/');
    // console.log('urlParts = ', urlParts)
    const catId = urlParts[urlParts.length - 1];
    // Check if id is a valid number, if not return null
    // return isNaN(id) ? null : id;
    // };

    return (
        <CatContextProvider catId={catId}>
            <SaleInformationOneCatProvider catId={catId}>
                <CatProfileSellChild />
            </SaleInformationOneCatProvider>
        </CatContextProvider>
    );
}
// const CatProfileSell = () => {
//     return (
//         <CatContextProvider>
//                 <CatProfileSellChild />
//         </CatContextProvider>
//     );
// }


const CatProfileSellChild = () => {
    const { translations, language } = useContext(LanguageContext);
    const { data: saleData, loading: saleLoading, error: saleError } = useContext(SaleInformationOneCatContext);
    const { data } = useContext(CatContext);
    const { isAuthenticated } = useAuth(); //  <AuthContext.Provider value={{ currentUser, login, logout, isAuthenticated, userRoles }}>
    const navigate = useNavigate();

    // const [isHovered, setIsHovered] = useState(false);
    // const [showClue, setShowClue] = useState(false);
    if (!data) { return <div>No data</div> }
    const cat = data
    // const { catContextValue: catData, loading: catLoading, error: catError } = useContext(CatContext);
    // Handle loading states
    if (saleLoading) return <div>Loading...</div>;

    // // Handle errors
    if (saleError) return <div>Error: {saleError?.message}</div>;

    // Handle the case when no data is available
    if (!cat) return <div>No cat data available</div>;
    if (!saleData) return <div>No sale data available</div>;
    const saleInformation = saleData.data[0]
    // console.log('cat', cat);
    // console.log('saleInformation]', saleInformation)
    const catAva = '/images/catAva.jpg';
    // const [isAddedToFavorite, setIsAddedToFavorite] = useState(false);
    // const { isAuthenticated } = useAuth();
    // const navigate = useNavigate();
    // const location = useLocation();
    // const isMarketPage = location.pathname === '/market';

    // const OnClickFavorite = (e) => {
    //     e.stopPropagation();
    //     setIsAddedToFavorite(!isAddedToFavorite);
    // };

    const yearsTranslation = cat?.age?.years === 1
        ? translations['year']
        : cat?.age?.years > 4
            ? translations['let_years']
            : translations['years'];
    const monthsTranslation = cat?.age?.months === 1
        ? translations['month']
        : cat?.age?.months > 4
            ? translations['months5']
            : translations['months'];
    const daysTranslation = translations.days;

    const birthdate = cat.cat_birthdate ? cat.cat_birthdate : '~12 may 2015';
    const years = cat?.age?.years ? cat?.age?.years : '';
    const months = cat?.age?.months ? cat?.age?.months : '';
    const days = cat?.age?.days ? cat?.age?.days : '';
    const yearsText = cat?.age?.years ? `${years} ${yearsTranslation}` : '';
    const monthsText = cat?.age?.months ? `${months} ${monthsTranslation}` : '';
    let catAge;
    if (days > 0) {
        catAge = `(${yearsText} ${monthsText} ${days} ${daysTranslation})`;
    } else {
        catAge = `(${yearsText} ${monthsText})`;
    }

    // const handleMouseEnter = () => {
    //     setShowClue(true);
    // };

    // const handleMouseLeave = () => {
    //     setShowClue(false);
    // };

    // const handleButtonClick = () => {
    //     if (isAuthenticated) {
    //         navigate(`/order/${cat.id}`, { state: { saleCat: cat } });
    //     } else {
    //         navigate(`/join`);
    //     }
    // };
    const handleButtonClick = () => {
        if (isAuthenticated
        ) {
            navigate(`/order/${cat.id}`, { state: { saleCat: cat } });
        } else {
            navigate(`/join`);
        }
    };

    return (
        <div className="cat-profile-sale__container">

            <div className='cat-profile-sale__left'
                key={cat?.id}
            // onMouseEnter={() => setIsHovered(true)}
            // onMouseLeave={() => setIsHovered(false)}
            >
                <div className="catAvaRelative">
                    {/* <Link className='nolink' to={`/cat-profile-sell/${cat.id}`}> */}
                    <img className="catCardImg" src={cat?.cat_ava_url ? cat?.cat_ava_url : catAva} alt={cat?.cat_ava_url ? ('Photo of ' + cat?.cat_name) : 'catAva'} />
                    {/* </Link> */}

                    <div className="iconMarsWrap">
                        {cat?.sex === "male" ? (
                            <FontAwesomeIcon icon={faMars} className="iconMars" />
                        ) : (
                            <FontAwesomeIcon icon={faVenus} className="iconVenus" />
                        )}
                    </div>

                    <div className="idWrap">
                        <div className="id">
                            ID {cat?.id ? cat?.id : ' ~12345678'}
                        </div>
                    </div>

                    {/* <div
                    onClick={OnClickFavorite}
                    className={`${styles.iconHeartWrap} ${isAddedToFavorite ? styles.active : ''}`}
                >
                    <FontAwesomeIcon icon={faHeart} className={styles.iconHeart} />
                </div> */}
                </div>
            </div>

            <div className="cat-profile-sale__desc">
                {/* <Link className='nolink' to={`/cat-profile-sell/${cat.id}`}> */}
                {/* <div className="descUp"> */}
                <div className="cat-profile-sale__header">
                    <strong>
                        {cat?.cat_name_cattery_prefix || '~Lambent Eyes'} {cat?.cat_name || '~Arny'} {cat?.cat_name_suffix || '~of Suffix'}
                    </strong>
                    <span className='cat-profile-sale__header_sale'> {translations.sale}</span>
                </div>
                <span className="cat-profile-sale__header_breed"> {language === 'ru' ? cat.breed_ru : cat.breed_en || '~Scottish Fold'}</span>
                <div className="descPriceWrap">
                    <div className="descPrice">
                        {saleInformation.price_pet || "~1000"}
                        <div className="descPriceText">{saleInformation.currency_abb || '~CAD'}</div>
                    </div>
                    <div className='cat-profile__clue' data-clue='Price for breeding (with breeding rights)'>
                        <div className="descPrice"
                        // onMouseEnter={handleMouseEnter}
                        // onMouseLeave={handleMouseLeave}
                        >
                            <FontAwesomeIcon className="iconMarsAndVenus" icon={faMarsAndVenus} />
                            {saleInformation.price_breeding || "~1000"}
                            <div className="descPriceText">{saleInformation.currency_abb || '~CAD'}</div>
                        </div>
                    </div>
                </div>

                {/* </div> */}
                {/* </Link> */}

                {/* <div className="descBottom"> */}
                <table className="cat-profile-sale__table">
                    <tbody>
                        {/* <tr>
                            <td>Титул</td>
                            <td>GCh (WCF), GCH (TICA), CH (WCF) ...</td>
                        </tr> */}
                        <tr>
                            <td>{translations.color}</td>
                            <td>
                                <div className="icon">
                                    <FontAwesomeIcon icon={faPalette} />
                                    {cat.main_eng_short && ` ${cat.main_eng_short}`}
                                    {cat.gold && ` ${cat.gold?.toLowerCase()}`}
                                    {cat.white && ` ${cat.white?.toLowerCase()}`}
                                    {cat.tabby && ` ${cat.tabby?.toLowerCase()}`}
                                    {cat.point && ` ${cat.point?.toLowerCase()}`} {cat.eye && `with ${cat.eye} eyes.`}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>{translations.ems_code}</td>
                            <td>
                                <div className="icon">
                                    <FontAwesomeIcon icon={faPalette} />{cat.colorFullEms}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>{translations.eye_color}</td>
                            <td>
                                <div className="icon">
                                    <FontAwesomeIcon icon={faEye} />{cat.eye}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>{translations.sex}</td>
                            <td>
                                <div className="icon">
                                    <FontAwesomeIcon icon={faVenusMars} />{cat.sex}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>{translations.birthdate}</td>
                            <td>
                                <div className="icon">
                                    <FontAwesomeIcon icon={faCakeCandles} />{birthdate}<span className="age">{catAge}</span>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>{translations.owner}</td>
                            <td>
                                <div className="icon">
                                    <FontAwesomeIcon icon={faUser} />{cat.last_name} {cat.first_name}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>{translations.cattery}</td>
                            <td>
                                <div className="icon">
                                    <FontAwesomeIcon icon={faCopyright} />{cat.cattery ? cat.cattery : '-'}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>{translations.location}</td>
                            <td>
                                <div className="icon">
                                    <FontAwesomeIcon icon={faLocationDot} />
                                    <span className="descBottomTextSmall">{language === 'ru' ? cat?.country_ru : cat?.country_en}</span>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>{translations.delivery}</td>
                            <td>
                                <div className="icon">
                                    <FontAwesomeIcon icon={faTruck} />
                                    <span className="descBottomTextSmall">{saleInformation.delivery_text}</span>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>{translations.ready_to_move}</td>
                            <td>
                                <div className="icon">
                                    <FontAwesomeIcon icon={faHouse} />
                                    <span className="descBottomTextSmall">{saleInformation.ready_to_move_at}</span>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <button className='joinButton' onClick={handleButtonClick}>
                    {translations.market.book}
                </button>
                <div className="cat-profile-sale__info">
                    <span>Market ID: {saleInformation.id}</span> Updated at: {saleInformation.updated_at}</div>
            </div>
        </div>
        // </div>

    );
}

export default CatProfileSell;
