import React, { useEffect, useReducer} from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import FormInput from '../../components/FormInput/FormInput';
import "./createPedigree.scss"
import { useAuth } from '../../context/authContext';
import { formatValuesForSelect } from '../../utils/formatValuesForSelect';
import { UploadFile } from '../../components/UploadFile/UploadFile'
import { HandleUpload } from '../../components/UploadFile/HandleUpload';
import { INITIAL_STATE, formReducer } from './formReducer';
// import http from '../../services/httpService';
import { fetchCatSys, postPedigree } from '../../services/api';

///// Component EditMetric can add a new metric or edit an existing metric ///// states with useState look at formReducer commnts.

const CreatePedigree = () => {

  const [state, dispatch] = useReducer(formReducer, INITIAL_STATE);
  const BASE_URL = process.env.REACT_APP_API_URL; //need only for UploadFile - latter maybe better to refactor

  const navigate = useNavigate();
  const auth = useAuth();
  const location = useLocation();
  const currentUserId = auth.currentUser.id;
  const catId = location.pathname.split("/")[2] //http://localhost:3001/post/1 - we take a number from URL address in browser

  const inputs = [
    {
      id: 1,
      name: "num",
      type: "text",
      placeholder: "Pedigree number",
      errorMessage: "Please, only numbers, letters, space and ./- (3-100 symbols) are allowed",
      label: "Pedigree number*",
      pattern: "^[А-Яа-яA-Za-z0-9 .\\/\\-]{3,100}$", //This pattern allows for any uppercase or lowercase letters, digits, and a single space, with a minimum length of 3 and a maximum length of 50
      required: true,
      additional: "Allow numbers, letters (3-100 symbols), space and ./-",
      maxLength: 100 // limit to 100 characters
      // className:"joinInput"
    },
    {
      id: 2,
      name: "issued_by",
      type: "text",
      placeholder: "Cat club or other organization name",
      label: "Pedigree was issued by (Club or System)",
      maxLength: 50, // limit to 50 characters
      errorMessage: "Please, write an organization which issued a document, it should be 3-50 symbols",
      pattern: `^[А-Яа-яA-Za-z0-9 "]{3,50}$`, //This pattern allows for any uppercase or lowercase letters, digits, and a single space, with a minimum length of 3 and a maximum length of 50
      required: false,
      additional: `Allow latin and cyrilic letters, numbers, space and "" (3-50 symbols)`
      // className:"joinInput"
    },
    {
      id: 3,
      name: "cat_systems_id",
      type: "select", // Change the type to 'textarea'
      placeholder: "System issued a document",
      label: "Pedigree was issued by (System)*",
      errorMessage: "System can't be empty",
      required: true,
      maxLength: 100 // limit to 100 characters
    },
  ]

  useEffect(() => {
    const fetchCatSystems = async () => {
      try {
        const res = await fetchCatSys()
        // console.log('res', res)
        const dataforSelect = formatValuesForSelect(res, 'name', 'abb');
        dispatch({ type: "FETCHED_CAT_SYSTEMS", payload: dataforSelect })
        dispatch({ type: "CATID", payload: catId })
      } catch (error) {
        console.log(error);
      }
    }
    fetchCatSystems()
  }, [catId])

  // Everytime file is uploaded (state in filePa) useEffect is evoked
  useEffect(() => {
    if (state.filePa) {
      // console.log('state.filePa is TRUE from useEffect', state.filePa);
      final();
      dispatch({ type: "ERROR_RESET" })
    }
    else { dispatch({ type: "ERROR", payload: "Attach a file" }) }
  }, [state.filePa]);

  const handleChangeFile = (file) => {
    // console.log('file from handleChangeFile', file)
    dispatch({ type: "CHANGE_FILE", payload: file })
  }

  //UPLOAD FILE to SERVER to folers uploads/user_id/file-name. 'metric' here is the prefix which isert into file name before saving it.
  //we need an async here (to use await)
  const uploadToWrap = () => {
    async function uploadTo() {
      // console.log('up    load  To evoked  state.selectedFile', state.selectedFile)
      // console.log('auth.currentUser.id', auth.currentUser.id)
      // console.log('uploadTo catId ', +state.values.catId)

      const fileP = await HandleUpload(state.selectedFile, `${BASE_URL}/upload`, currentUserId, 'pedigree', +state.values.catId); //'pedigree' - is a prefix which is added to file name befroe saving (backend)
      console.log('fileP.filePath', fileP.filePath);
      dispatch({ type: "SET_FILE_URL", payload: fileP.filePath });

    } uploadTo();
  }

  // ONLY WHEN WE HAVE A IMG_URL in our state we send all values/state to server
  const final = async () => {
    try {
      //creating a new entity (sending post request to server to save in DB)
      await postPedigree(state.values)
      navigate(`/cats/${catId}`);
    }
    catch (err) {
      console.log('Error submiting form:', err);
      // dispatch({type: 'SET_ERROR', payload: err.message})
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log('state.selectedFile', state.selectedFile)
    if (state.currentSystem) {
      if (state.selectedFile) {
        // console.log('state.selectedFile true from handleSubmit')
        uploadToWrap();
      } else { console.error('Err') }
    } else {
      console.error('Файл не выбран');
    }
    // sendValuesToServer(state.filePa, state.values)

  };

  const onChange = (e) => {
    dispatch({ type: "CHANGE_INPUT", payload: { name: e.target.name, value: e.target.value } });
  };


  //SELECT built-in selectedOption
  const onChangeSystem = (selectedOption) => {
    dispatch({ type: "CHANGE_SELECT_SYSTEM", payload: selectedOption });
  }

  const cancelEdit = () => {
    navigate(`/cats/${catId}`)
  }

  return (
    <div className="edit-metric">
      <form className="cattery-form" onSubmit={handleSubmit}>
        <h1>Pedigree - creating </h1>
        <p>Please, fill all fields</p>
        {state.values && (
          inputs.map((input) => (
            //valueSelect send to FormInput value as props
            <FormInput
              {...input}
              key={input.id}
              currentValue={state.currentSystem}
              options={state.catSystems}
              onChangeSelect={onChangeSystem}
              value={state.values[input.name] || ''}
              onChange={onChange} />
          ))
        )}
        <UploadFile selectedFile={state.selectedFile} onChangeFile={handleChangeFile} />
        <button type='submit' className='joinButton'>Send</button>
        <button type='button' className='button-light' onClick={cancelEdit}>Cancel</button>
        {state.err && <div className='errorMessage'> {state.err}</div>}
      </form>
    </div>
  )

}

export default CreatePedigree
