import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import FormInput from '../../components/FormInput/FormInput';
import "./editPedigree.scss"
import { useAuth } from '../../context/authContext';
import { formatValuesForSelect } from '../../utils/formatValuesForSelect';
// import Select from 'react-select';
import { UploadFile } from '../../components/UploadFile/UploadFile'
import { HandleUpload } from '../../components/UploadFile/HandleUpload';
// import http from '../../services/httpService';
import { fetchCatSys, fetchOnePedigree, putPedigree, putPedigreeURL } from '../../services/api';

/////////// ----- without uploading a file - can not be submitted - needs to be refactored latter.


///// Component EditMetric can add a new metric or edit an existing metric /////
// const someReducer = (state, action) => {
//   switch (action.type) {
//     case "FETCHED_ONE_PEDIGREE":
//       return {};
//     case "FETCHED_CAT_SYSTEMS":
//       return {};
//     case "CURRENT_CAT_SYSTEMS":
//       return {};
//     case "CHANGE_INPUT":
//       return {};
//     case "CHANGE_FILE":
//       return {};
//     case "SET_ERROR":
//       return {};
//     default:
//       return state;
//   }
// }
// const INITIAL_STATE = {
//   values: {
//     id: null,
//     num: '',
//     issued_by: '',
//     pedigree_img_url: '',
//     cat_systems_id: '',
//   },
//   isCreating: true,
//   selectError: false,
//   currentSystem: '',
//   err: null,
//   catSystems: [],
//   selectedFile: null,
//   filePa: null,
// }

const EditPedigree = () => {
  // const [state, dispatch] = useReducer(someReducer, INITIAL_STATE);

  const navigate = useNavigate();
  const auth = useAuth();
  const location = useLocation();
  const currentUserId = auth.currentUser.id;

  // const catId = new URLSearchParams(location.search).get('id');
  const catId = location.pathname.split("/")[2] //http://localhost:3001/post/1 - we take a number from URL address in browser
  const { pedigree_id: pedigreeId } = useParams() //ranaming here
  // console.log('pedigreeId', pedigreeId); //'metirc_id' nameing was establish in APP.js
  // We need to get metric values. We get from cat table metric_id and JOIN all metric data.
  // const [isFilePath, setIsFilePath] = useState(false);

  const [values, setValues] = useState(
    {
      id: null,
      num: '',
      issued_by: '',
      pedigree_img_url: '',
      cat_systems_id: '',
      catId
    }
  );

  const [selectError, setSelectError] = useState({
    fileError: "",
    systemError: ""
  });

  const BASE_URL = process.env.REACT_APP_API_URL; //need only for UploadFile - latter maybe better to refactor

  const [currentSystem, setCurrentSystem] = useState('')
  const [catSystems, setCatSystems] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isImageSaved, setIsImageSaved] = useState("");

  const inputs = [
    {
      id: 1,
      name: "num",
      type: "text",
      placeholder: "Pedigree number",
      errorMessage: "Please, only numbers, letters, space and ./- (3-100 symbols) are allowed",
      label: "Pedigree number*",
      pattern: "^[А-Яа-яA-Za-z0-9 .\\/\\-]{3,100}$", //This pattern allows for any uppercase or lowercase letters, digits, and a single space, with a minimum length of 3 and a maximum length of 50
      required: true,
      maxLength: 100, // limit to 100 characters
      additional: "Allow numbers, letters (3-100 symbols), space and ./-"
    },
    {
      id: 2,
      name: "issued_by",
      type: "text",
      placeholder: "Issued by",
      label: "Pedigree was issued by (Club or System)*",
      maxLength: 50, // limit to 100 characters
      errorMessage: "Please, write an organization which issued a document, it should be 3-50 symbols",
      pattern: `^[А-Яа-яA-Za-z0-9 "\\-]{3,50}$`, //This pattern allows for any uppercase or lowercase letters, digits, and a single space, with a minimum length of 3 and a maximum length of 50
      required: false,
      additional: `Allow latin and cyrilic letters, numbers, space and ""- (3-50 symbols)`
    },
    {
      id: 3,
      name: "cat_systems_id",
      type: "select",
      placeholder: "System issued a document",
      errorMessage: "System can't be empty",
      label: "Pedigree was issued by (System)*",
      required: true,
      maxLength: 100 // limit to 100 characters
    }
  ]

  useEffect(() => {
    const fetchPedData = async () => {
      if (pedigreeId) {
        try {
          const resDataOnePed = await fetchOnePedigree(catId, currentUserId)
          // console.log('res.data', resOnePed.data)
          setValues(resDataOnePed);

          const res = await fetchCatSys()
          const dataforSelect = formatValuesForSelect(res, 'name', 'abb');
          setCatSystems(dataforSelect); //better like this setCatSystems(dataforSelect) - without callback arraw function inside
          //Show the current metric's system (from DB)
          const filtered = dataforSelect.filter(sys => sys.value === resDataOnePed.cat_systems_id)
          // console.log('filtered', filtered)
          setCurrentSystem(filtered);

        } catch (error) { console.log(error); }
      }
    }
    fetchPedData();
  }, [currentUserId, catId, pedigreeId]);

  // useEffect(() => {
  //   const fetchCatSystems = async () => {
  //     // console.log('currentUser', currentUser)
  //     try {
  //       const res = await fetchCatSys()
  //       const dataforSelect = formatValuesForSelect(res, 'name', 'abb');
  //       setCatSystems(dataforSelect); //better like this setCatSystems(dataforSelect) - without callback arraw function inside
  //       // console.log('catSystem', dataforSelect)
  //       if (pedigreeId) {
  //         //Show the current metric's system (from DB)
  //         const filtered = dataforSelect.filter(sys => sys.value === values.cat_systems_id)
  //         // console.log('filtered', filtered)
  //         setCurrentSystem(filtered);
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   }
  //   fetchCatSystems()
  // }, [values.cat_systems_id, pedigreeId])

  // Everytime file is uploaded (state in filePa) useEffect is evoked
  // useEffect(() => {
  //   if (filePa) {
  //     final(filePa, values);
  //     setSelectError({ fileError: "" });
  //   }
  //   else {
  //     setSelectError({ fileError: "Upload a photo" });
  //   }
  // }, [filePa]);

  const handleChangeFile = (file) => {
    setSelectedFile(file);
    //UPLOAD FILE to SERVER to folers uploads/user_id/file-name. 'metric' here is the prefix which isert into file name before saving it.
    // console.log('handleChangeFile file', file)
    // On the Frontend (when sending the file name)
    // Regular expression to match non-Latin letters, non-numbers, non-underscore, and non-dash characters    
    // let cleanFileName = file.name.replace(/ /g, "_").replace(/[^\w-]/g, '');
    // console.log('handleChangeFile encodedFileName = ', cleanFileName)

    const uploadPedigree = async () => {
      const pedigreeFile = await HandleUpload(file, `${BASE_URL}/upload`, currentUserId, 'pedigree', catId); //'pedigree' - is a prefix which is added to file name befroe saving (backend)
      // setFilePa(fileP);
      // console.log(' handleChangeFile pedigreeImgUrl.filePath = ', pedigreeFile.filePath)
      // setValues((prev) => ({ ...prev, pedigree_img_url: fileP?.filePath }))

      if (pedigreeFile) {
        const filePath = pedigreeFile.filePath;
        // console.log('pedigreeFile is here filePath = ', filePath)
        setSelectError((prev) => ({ ...prev, fileError: '' }));
        await putPedigreeURL(pedigreeId, filePath)
        // console.log('handleChangeFile res = ', res)
        setIsImageSaved("Image was saved!");
        setValues((prev) => ({ ...prev, pedigree_img_url: filePath }))

      }
    }
    uploadPedigree()
  }


  const final = async () => {
    try {
      await putPedigree(pedigreeId, values)
      navigate(`/cats/${catId}`);
    }
    catch (err) {
      console.log('Error submiting form:', err)
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (currentSystem) {
      if (selectedFile || values.pedigree_img_url) {
        setSelectError({ fileError: "" });
        final();
      } else {
        final();
        setSelectError({ fileError: "Upload a photo" });
      }
    } else {
      setSelectError({ systemError: "Chose a system" });
    }
  };

  const onChange = (e) => {
    setValues((prev) => ({ ...prev, [e.target.name]: e.target.value }))
  };

  const onChangeSystem = (selectedOption) => {
    setCurrentSystem(() => selectedOption);
    setValues((prev) => ({ ...prev, cat_systems_id: selectedOption.value }))
    if (selectedOption.value) {
      setSelectError({ fileError: "" });
    }
  }

  const cancelEdit = () => {
    navigate(`/cats/${catId}`)
  }

  return (
    <div className="edit-metric">
      <form className="cattery-form" onSubmit={handleSubmit}>
        <h1>Pedigree </h1>
        {values && (
          inputs.map((input) => (
            //valueSelect send to FormInput value as props
            <FormInput
              {...input}
              key={input.id}
              currentValue={currentSystem}
              options={catSystems}
              onChangeSelect={onChangeSystem}
              value={values[input.name] || ''}
              onChange={onChange} />))
        )}
        {/* it's rendered if we don't have any metric in our values */}
        {/* {values && !values[0] && (
          inputs.map((input) => (
            <FormInput key={input.id} options={catSystems} {...input} onChangeSelect={onChangeSystem} value={values[input.name] || ''} onChange={onChange} />))
          )} */}
        {/* 
        <div className="cattery-selectInput">
        <label className="selectInput__label">Your cattery system (on your certificate)*</label>
        <Select className="selectInput__input" options={catSystems} onChange={onChangeSystem} value={currentSystem} />
      </div> */}
        {/* {console.log('values from EditMEtric from return', values)}*/}
        {/* DOESN'T WORK - I don't see IMAGE - don't know why */}
        {values.pedigree_img_url &&
          <figure className='metric-img'>
            <div>
              <figcaption>Current pedigree in database</figcaption>
              <img src={values?.pedigree_img_url} alt="Cat-pedigree" />
            </div>
          </figure>
        }
        {selectError.systemError && <div className='messageError'>{selectError.systemError}</div>}
        <UploadFile selectedFile={selectedFile} onChangeFile={handleChangeFile} />
        <strong>{isImageSaved}</strong>
        <button type='submit' className='joinButton'>Send</button>
        <button type='button' className='button-light' onClick={cancelEdit}>Cancel</button>
        {selectError?.fileError && <div className='messageError'>{selectError.fileError}</div>}
        {/* <span>Do you have an account? <Link to="/login">Login</Link></span> */}
      </form>
    </div>
  )

}

export default EditPedigree
