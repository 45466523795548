// import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import FormInput from '../../components/FormInput/FormInput';
import "./join.scss";
import { fetchCountries, join } from '../../services/api';
// import ym from 'react-yandex-metrika';
import { useContext } from "react";
import { LanguageContext } from "../../context/langContext";
import { formatValuesForSelect } from '../../utils/formatValuesForSelect';


const Join = () => {
  const [values, setValues] = useState({
    first_name: "",
    last_name: "",
    email: "",
    birthday: "",
    countries_id: "",
    currentCountryInSelect: { value: "", label: "Select Country" },  // Ensure this is not an empty object,
    password: "",
    confirmPassword: ""
  });

  // ym('hit', '/join');

  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  // Add a new state to track the validity of each input
  const [inputErrors, setInputErrors] = useState({});
  const { translations } = useContext(LanguageContext)
  const [countries, setCountries] = useState([])
  const [currentCountry, setCurrentCountry] = useState('')


  const [err, setError] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchCunt = async () => {
      // console.log('currentUser', currentUser)
      try {
        // const userData = await fetchUserById(currentUserId) //here is come res.data (not just res.)
        // setValues(userData);
        // const formattedDate = formatDateFromDB(userData.birthday)
        // setValues((prev) => ({ ...prev, birthday: formattedDate }))
        // // ----
        const res = await fetchCountries()
        const dataforSelect = formatValuesForSelect(res, 'en');
        // console.log('countries', dataforSelect)
        setCountries(dataforSelect); //better like this setCatSystems(dataforSelect) - without callback arraw function inside

      } catch (error) {
        console.log(error);
      }
    }
    fetchCunt()
  }, [])

  const onChangeCountries = (selectedOption) => {
    // setCurrentCountry(() => selectedOption);
    setValues((prev) => ({ ...prev, currentCountryInSelect: selectedOption, countries_id: selectedOption.value }))
  }

  const timeoutRef = useRef();

  const onChange = (e) => {
    setValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    // Clear previous timeout if it exists
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    // Set a new timeout
    timeoutRef.current = setTimeout(() => {
      setInputErrors(prev => ({
        ...prev,
        [e.target.name]: !e.target.validity.valid
      }));
    }, 300); // Adjust the time (in ms) as neededsetInputErrors((prev) => ({
  };

  // Use useEffect for cleanup
  useEffect(() => {
    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, []);

  const handleCheckboxChange = (event) => {
    setIsCheckboxChecked(event.target.checked);
  }

  const inputs = [
    {
      id: "first_name",
      name: "first_name",
      type: "text",
      placeholder: translations.join.first_name_placeholder,
      label: translations.join.first_name,
      pattern: "^[А-Яа-яA-Za-z0-9]{3,16}$",
      required: true,
      onChange: onChange,
      additional: translations.join.first_name_additional,
      errorMessage: translations.join.first_name_error,
    },
    {
      id: 2,
      name: "last_name",
      type: "text",
      placeholder: translations.join.last_name_placeholder,
      label: translations.join.last_name,
      pattern: "^[А-Яа-яA-Za-z0-9]{3,16}$",
      required: true,
      onChange: onChange,
      additional: translations.join.last_name_additional,
      errorMessage: translations.join.last_name_error,
    },
    {
      id: 3,
      name: "email",
      type: "email",
      autoComplete: "username",
      placeholder: translations.join.email_placeholder,
      errorMessage: translations.join.email_error,
      label: translations.join.email_label,
      required: true,
      onChange: onChange,
    },
    {
      id: 4,
      name: "birthday",
      type: "date",
      errorMessage: "",
      label: translations.join.birthday_label,
      required: true, //without birthdate I can't send date to mysql - error.
      onChange: onChange
    },
    {
      id: "country",
      name: "currentCountryInSelect",
      type: "select",
      errorMessage: "",
      onChange: onChangeCountries,
      options: countries,
      label: translations.join.country_of,
      required: true, //without birthdate I can't send date to mysql - error.
    },
    {
      id: "password",
      name: "password",
      type: "password",
      autoComplete: "new-password",
      placeholder: translations.join.password_placeholder,
      errorMessage: translations.join.password_error,
      label: translations.join.password_label,
      pattern: `^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^＆*]{8,20}$`,
      required: true,
      onChange: onChange,
      additional: translations.join.password_additional
    },
    {
      id: 6,
      name: "confirmPassword",
      type: "password",
      autoComplete: "new-password",
      placeholder: translations.join.confirm_password_placeholder,
      errorMessage: translations.join.confirm_password_error,
      label: translations.join.confirm_password_label,
      pattern: values.password,
      required: true,
      onChange: onChange,
    },
  ];

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      const resjoin = await join(values);
      console.log('resjoin = ', resjoin);
      navigate('/login');
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        console.log('ex.response.data', ex.response.data);
        console.log('ex.response', ex.response);
        if (ex.response.status === 400) {
          setError(ex.response.data.error);
        }
      }
    }
    // error status and error text come from server auth.js - we write it!
  };



  // console.log('inputErrors =', inputErrors)

  return (
    <div className="join">
      <form className="joinform" onSubmit={handleSubmit}>
        <h1>{translations.join.header}</h1>
        {/* {values && (
          inputs.map((input) => (
            //valueSelect send to FormInput value as props
            <FormInput key={input.id} valueSelect={currentCountry} options={countries} {...input} onChangeSelect={onChangeCountries}
              value={values[input.name] || ''} onChange={onChange} />
          ))
        )} */}
        {values && (
          inputs.map((input) => (
            <FormInput
              key={input.id}
              {...input}
              // valueselect={currentCountry}
              options={input.options}
              // onChangeSelect={onChangeCountries}
              value={values[input.name] || ''}
              onChange={input.onChange}
              isInvalid={inputErrors[input.name]} // Pass the validity state to FormInput 
              errorMessage={input.errorMessage}
            />
          ))
        )}
        <span className='span-terms'>
          <input type='checkbox' onChange={handleCheckboxChange} /> {translations.join.accept1}<Link to="/terms">{translations.join.terms}</Link> {translations.join.accept2} <Link to="/privacy">{translations.join.policy}</Link>
        </span>
        <button className='joinButton' disabled={!isCheckboxChecked} >{translations.send}</button>
        {err && <p className='errorMessage'> {err}</p>}
        <span>{translations.join.do_you_have_an_account} <Link to="/login">{translations.auth.login}</Link></span>
      </form>
    </div>
  )
}

export default Join
