
// import axios from 'axios';
import http from '../services/httpService';

// const BASE_URL = process.env.REACT_APP_API_URL;
// BASE_URL in .env files and then grabed in /services/httpService.js


////// USERS
export const fetchUsers = async () => {
    const { data } = await http.get(`/users`); //other option  await http.get(`${BASE_URL}/users`);
    return data;
};

export const putUser = (userEditInfo) => {
    console.log('API.js | putUser | userEditInfo', userEditInfo)
    return http.put(`/me/edit`, userEditInfo)
};

////// ME
export const postUserAva = (fileP) => {
    // console.log('API.js | postUserAva | fileP = ', fileP)
    return http.post(`/me/useravaurl`, fileP);
};

export const fetchUser = async () => {
    try {
        const { data } = await http.get(`/me`);
        // console.log("API.js | fetchUser | data = ", data)
        return data;
    } catch (error) {
        console.error('API.js | fetchUser | Error fetching user:', error);
    }
};

export const fetchUserSettings = async () => {
    try {
        // console.log('API.js | fetchUserSettings evoked!')
        const { data } = await http.get(`/me/settings`);
        // console.log('API.js | fetchUserSettings | data = ', data);
        return data;
    } catch (error) {
        console.error('API.js | fetchUserSettings | Error fetching user settings:', error);
        // Optionally, you could return a default value or rethrow the error:
        // return null; 
        throw new Error('Failed to fetch user settings');
    }
};

export const changeEmail = (writtenEmail) => {
    return http.put(`/me/change-email`, writtenEmail)
};

////// AUTH
export const loginUser = async (credentials) => {
    try {
        // const res = await http.post(`/auth/login`, credentials);
        const res = await http.post(`/auth/login`, credentials, { withCredentials: true });
        // console.log('res from api.js from loginUser res = ', res)
        return { success: true, data: res.data };
    } catch (error) {
        console.log('API.js | loginUser | error = ', error)
        if (error.response && error.response.status === 400 && error.response.data === 'Wrong username or password!') {
            return { success: false, message: 'Wrong username or password. Please try again.' };
        } else if (error.response && error.response.status === 404 && error.response.data === 'User not found!') {
            return { success: false, message: 'User not found!' };
        } else {
            console.error('API.js | loginUser | ELSE Error:', error);
            return { success: false, message: 'An unexpected error occurred. Please try again later.' };
        }

    }
};

// version 1:
// export const join = (credentials) => {
//     return http.post(`/auth/register/`, credentials)
// };

//version 2:
export const join = async (credentials) => {
    const resjoin = await http.post(`/auth/register`, credentials, { withCredentials: true });
    // console.log('from client api.js join resjoin.data = ', resjoin.data);
    return resjoin;
};

export const logoutApi = () => {
    // console.log('from client api.js join resjoin.data = ');
    return http.post(`/auth/logout`, { withCredentials: true });
};

export const checkAuthStatus = async () => {
    // console.log('checAuthStatus evoked from api.js')
    try {
        const response = await http.get('/auth/check-status', { withCredentials: true });
        //option is included to ensure that cookies (which may contain the user's session ID or auth token) are sent along with the request.
        // console.log('response.data from api from checkAuthStatus', response.data)
        return response.data;
    } catch (error) {
        console.error('Error during checking auth status: ', error);
        throw error;
    }
}

export const changePasswordInDB = (cred) => {
    // console.log('password from changePasswordInDB: ', cred)
    return http.put(`/auth/change-password`, cred)
};

export const checkEmailExists = (email) => {
    // console.log('email from api.js from checkEmailExists', email)
    return http.get(`/auth/check-email-exists?email=${email}`) //The HTTP GET request does not usually send a request body. Instead, it sends data as query parameters or URL parameters.
};

////// CATS //////
// export const fetchCats = async () => {
//     const { data } = await http.get(`/cats`);
//     return data;
// };

// export const fetchMarektSellingCats = async () => {
//     const { data } = await http.get(`/cats/selling`);
//     // console.log('data from fetchMarektSellingCats ', data)
//     return data;
// };

export const fetchCatsWithColor10Free = async (query, p) => {
    const { data } = await http.get(`/cats/all-with-color-10-free?q=${query}&p=${p}`)
    return data
};
export const fetchCatsWithColor10ByGender = async (query, p) => {
    const { data } = await http.get(`/cats/all-with-color-10-by-gender?q=${query}&p=${p}`)
    return data
};

export const fetchUserIdByCatId = async (catId) => {
    const res = await http.get(`/cats/user-id-by-cat-id/${catId}`);
    // console.log('fetchUserIdByCatId res = ', res)
    if (res?.status >= 200 && res?.status < 300) {
        return res.data
    }
    else {
        throw new Error(`fetchUserIdByCatId /cats/user-id-by-cat-id/${catId}, recieved ${res?.status}`)
    }
};

export const fetchOneCat = async (catId) => {
    const res = await http.get(`/cats/${catId}`);
    if (res.status >= 200 && res.status < 300) {
        return res.data
    }
    else {
        throw new Error(`Could not fetch OneCat /cats/${catId}, recieved ${res.status}`)
    }
};

export const fetchOneCatAndColor = async (catId) => {
    try {
        const res = await http.get(`/cats/cat-and-color/${catId}`);
        // console.log('API.js | fetchOneCatAndColor | res', res);

        if (res.status >= 200 && res.status < 300) {
            return res.data;
        } else {
            throw new Error(`Could not fetch OneCat /cats/cat-and-color/${catId}, received ${res.status}`);
        }
    } catch (error) {
        console.error('Error in fetchOneCatAndColor:', error);
        throw error;
    }
};


// export const fetchMyCats = async (currentUserId) => {
//     const res = await http.get(`/cats/mycats?user=${currentUserId}`)
//     if (res.status >= 200 && res.status < 300) {
//         return res.data
//     }
//     else {
//         throw new Error(`Could not fetch OneCat /cats/mycats?user=${currentUserId}, recieved ${res.status}`)
//     }
// };

export const getMyCats = async (isSell) => {
    const params = { isSell }

    // if (typeof isSell !== 'undefined') {
    //     params.isSell = isSell;
    // }

    // console.log("api.js getMyCats params = ", params);

    try {
        const res = await http.get(`/me/cats`, { params });
        // console.log("API.js | getMyCats | HTTP status =", res.status);
        if (res.status >= 200 && res.status < 300) {
            // console.log(" API.js | getMyCats | res.data =", res.data)
            return res.data
        }
        else {
            console.error(`Error: Received status code ${res.status} from /cats`);
            throw new Error(`Could not fetchSellCat usein API /cats , params, recieved ${res.status}`)
        }
    } catch (error) {
        console.error(`Failed to fetch cats for sale: ${error.message}`);
        throw new Error('Failed to fetch sell cats. Please try again later.');
    }

};

// HTTP requests from the client (e.g., a browser) to the server and want to include cookies, you need to configure your requests to include credentials.
export const fetchApplicationsNotReg = async (currentUserId) => {
    const resNotSolved = await http.get(`/applications/not-solve`, {
        params: { userId: currentUserId },
        withCredentials: true
    })
    if (resNotSolved.status === 204) {
        console.log('We DO NOT have applications!')
        return; // Added return statement to stop execution here
    }

    if (resNotSolved.data.data) {
        //distracture answer from db res.status(200).json({ message: "Applications retrieved successfully", catIds: catIds, application: application_id});
        const ids = resNotSolved.data.data.map(applications => applications.cat_id);
        // console.log('We have ids = ', ids);
        const res = await http.get(`/cats/notregistered`, {
            params: { ids },
            withCredentials: true
        })
        if (res.status >= 200 && res.status < 300) {
            // console.log('res.data', res.data)
            return res.data;
        }
        else {
            throw new Error(`Could not fetch OneCat /cats/notregistered, recieved ${res.status}`)
        }
    }
};

export const putCat = (values) => {
    return http.put(`/cats/${values.id}`, values)
};

export const putUserFromPrev = (data) => {
    console.log('user-from-prev data = ', data)
    return http.put(`/cats/user-from-prev`, data)
};


export const postCat = (values) => {
    return http.post(`/cats`, values);
};

// export const postParent = (values) => {
//     return http.post(`/cats/parent`, values);
// };

export const deleteCat = async (catId) => {
    console.log('deleteCat catId =', catId);
    http.delete(`/cats/${catId}`)
        .then(response => {
            // Successfully deleted user
            console.log('api deleteCat response = ', response);
        })
        .catch(error => {
            if (error.response && error.response.status === 400) {
                alert(error.response.data.message);  // Alert the user-friendly error message
            } else {
                alert('An unexpected error occurred.');
            }
        })
};

export const postCatAva = (catId, fileP) => {
    return http.post(`/cats/${catId}/catavaurl`, fileP);
};

export const putRegisterPedigree = (catId, currentUserId) => {
    return http.put(`/cats/${catId}/register-pedigree`, currentUserId)
};

export const deleteApplication = (catId, currentUserId) => {
    return http.put(`/cats/${catId}/delete-pedigree-application`, currentUserId)
};

export const updateOwnerAtCat = (catId) => {
    return http.put(`/cats/update-owner-at-cat/${catId}`)
};

////// CAT CHANGE OWNER
export const postCatChangeOwner = (catId) => {
    // console.log('api.js catChangeOwner filteredValue = ', catId) //userId we take from userInfo from validateMiddle
    return http.post("/cat-change-owner", catId)
};

export const fetchPrevOwnerFromCatChangeOwner = async (catId) => {
    const { data } = await http.get(`/cat-change-owner/${catId}`);
    return data
};

export const postCatChangeOwnerFromPrev = (data) => {
    return http.post(`/cat-change-owner/cat-change-owner-from-prev`, data) //{catId: 1, prevOwner: 6}
};

////// CATTERIES
export const postCattery = (values) => {
    return http.post(`/me/cattery`, values)
};

export const fetchMyCatteries = (currentUserId) => {
    return http.get(`/catteries?user=${currentUserId}`)
};

export const fetchOneCattery = (currentUserId, catteryId) => {
    return http.get(`/catteries/${catteryId}/edit`, currentUserId)
};

export const updateCattery = (catteryId, values) => {
    return http.put(`/catteries/${catteryId}`, values);
};

////// PEDIGREE
export const fetchOnePedigree = async (catId, currentUserId) => {
    const { data } = await http.get(`/pedigrees/${catId}`, currentUserId)
    return data
};

export const postPedigree = async (values) => {
    return await http.post(`/pedigrees`, values)
};

export const putPedigree = async (pedigreeId, values) => {
    return await http.put(`/pedigrees/${pedigreeId}`, values)
};

export const putPedigreeURL = async (pedigreeId, filePath) => {
    console.log('api.js putPedigreeURL evoked, filePath = ', filePath)
    return await http.put(`/pedigrees/url/${pedigreeId}`, { filePath: filePath })
};

////// METRIC
export const fetchOneMetric = async (catId, currentUserId) => {
    const { data } = await http.get(`/metrics/${catId}`, currentUserId)
    return data
};

export const postMetric = async (values) => {
    return await http.post(`/metrics`, values)
};

export const putMetric = async (metricId, values) => {
    return await http.put(`/metrics/${metricId}`, values)
};

/////// COLOR
export const fetchColor = async (catId) => {
    return await http.get(`/color/${catId}`);
};

export const putColor = async (values, newColor) => {
    return await http.put(`/color/${values.id}`, newColor)
};

export const postColor = (catInsertedId, newColor) => {
    return http.post(`/color`, { cat_id: catInsertedId, ...newColor })
};

// export const fetchMainColor = async () => {
//     return await http.get(`/color/main`);
// };

////// BREEDS
export const fetchBreeds = async () => {
    const { data } = await http.get(`/breeds`);
    return data;
};
export const fetchBreedById = async (id) => {
    const { data } = await http.get(`/breeds/${id}`);
    // console.log('data from fethcBreedById data[0]= ', data[0].ems)
    return data;
};

////// COUNTRIES
export const fetchCountries = async () => {
    try {
        const res = await http.get('/countries/en_ru')
        console.log("API.js | fetchCountries | res =", res);
        if (res.status >= 200 && res.status < 300) {
            // console.log(" API.js | getMyCats | res.data =", res.data)
            return res.data
        }
        else {
            console.error(`Error: Received status code ${res.status} from /countries/en`);
            throw new Error(`Could not fetchCountries, res.status = ${res.status}`)
        }
    } catch (error) {
        console.error(`Failed fetchCountries: ${error.message}`);
        throw new Error('Failed fetchCountries');
    }
};

////// CAT SYSTEMS
export const fetchCatSys = async () => {
    const { data } = await http.get(`/cat-systems`)
    return data
};

////// APPLICATIONS
export const fetchApplications = async (catId) => {
    const { data } = await http.get(`/applications/${catId}`)
    return data
};

export const sendToRegistration = (catId, pedigrees_id) => {
    return http.post(`/applications/send-to-registration/${catId}`, { pedigrees_id })

};

////// PARENTS
export const fetchParents = async (catId) => {
    const { data } = await http.get(`/parents/${catId}`);
    // console.log('data from fetchParents', data)
    return data
};

export const fetchMother = async (motherId) => {
    const { data } = await http.get(`/parents/mother/${motherId}`);
    return data
};

export const fetchFather = async (fatherId) => {
    const { data } = await http.get(`/parents/father/${fatherId}`);
    return data
};

export const deleteMother = (catId, currentUserId) => {
    return http.delete(`/parents/${catId}`, { data: { mother: true, userId: currentUserId } })
};

export const deleteFather = (catId, currentUserId) => {
    return http.delete(`/parents/${catId}`, { data: { father: true, userId: currentUserId } });
};

export const postMother = (motherId, catId) => {
    return http.post(`/parents/`, { motherId, catId });
};

export const postFather = (fatherId, catId) => {
    return http.post(`/parents/`, { fatherId, catId });
};

export const fetchAncestors = async (catId) => {
    const { data } = await http.get(`/parents/${catId}/ancestors`);
    // console.log('api.js fetchAncestors data = ', data)
    return data;
};

//////// RECOVERY
export const SendRecoveryEmail = (email, otp) => {
    // console.log('SendRecoveryEmail evoked')
    return http.post(`/send-recovery-email`, { OTP: otp, recipient_email: email })
};

export const fetchCatSalesAll = async () => {
    // console.log('API.js | fetchCatSalesAll evoked ')
    const url = `/sales/all-cats`;
    // console.log('API.js | fetchCatSalesAll | URL to be fetched: ', url);
    const { data } = await http.get(url);
    // console.log('API.js | fetchCatSalesAll | data = ', data)
    return data;
};

export const fetchSellingInformationById = async (catId) => {
    try {
        const res = await http.get(`/sales/${catId}`);
        // const dataS = data.data
        // console.log('Api.js | fetchSellingInformationById evoked  res = ', res)
        if (!res.data.length) {
            return { status: 'no_data', message: 'No sale information found' };
        }
        const data = res.data;
        return { status: 'success', data };
    } catch (error) {
        console.error('Error fetching sale information:', error);
        return { status: 'error', message: error.message };
    }
};

// if no such filteres cat we need to do a proper screen
export const fetchCatSaleFilter = async (breed) => {
    try {
        // console.log('fetchCatSalesFilter evoked breed =', breed)
        const { data } = await http.get(`/sales/cats-sales?${breed}`);
        // console.log('data from fetchCatSalesAll = ', data)
        return data;
    } catch (error) {
        if (error.response && error.response.status === 404) {
            console.log('No cats found for the given filter');
            return null; // or an empty array []
        } else {
            console.error('An error occurred:', error);
            throw error; // rethrow the error if it's not a 404
        }
    }
};

export const ApiUpdateIsActive = async (obj) => {
    try {
        // console.log('API.js | ApiUpdateIsActive | obj =', obj)
        // Destructure catId and isActive from the obj
        const { cat_id, is_active } = obj;
        const { data } = await http.put(`/sales/${cat_id}/is-active-update`, { is_active });// /:id/is-active-update
        // console.log('data from fetchCatSalesAll = ', data)
        return data;
    } catch (error) {
        if (error.response && error.response.status === 404) {
            console.log('No cats found for the given filter');
            return null; // or an empty array []
        } else {
            console.error('An error occurred:', error);
            throw error; // rethrow the error if it's not a 404
        }
    }
};

export const sendOrder = async (orderDetails) => {
    // console.log('sendOrder evoked orderDetails =', orderDetails)
    try {
        const response = await http.post('/order', orderDetails);
        return response.data;
    } catch (error) {
        // Log the error for debugging
        console.error('Error in sendOrder:', error);

        // You can customize this part to handle different types of errors
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error('Response error:', error.response.data);
            throw new Error('Failed to send order: ' + error.response.data.message);
        } else if (error.request) {
            // The request was made but no response was received
            console.error('Request error:', error.request);
            throw new Error('No response from server');
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error('General error:', error.message);
            throw new Error('Error in setting up the request: ' + error.message);
        }
    }
};

export const getOrders = async () => {
    // console.log('sendOrder evoked orderDetails =', orderDetails)
    try {
        const response = await http.get('/order');
        // console.log( 'api.js response = ', response)
        return response.data;
    } catch (error) {
        // Log the error for debugging
        console.error('Error in sendOrder:', error);

        // You can customize this part to handle different types of errors
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error('Response error:', error.response.data);
            throw new Error('Failed to send order: ' + error.response.data.message);
        } else if (error.request) {
            // The request was made but no response was received
            console.error('Request error:', error.request);
            throw new Error('No response from server');
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error('General error:', error.message);
            throw new Error('Error in setting up the request: ' + error.message);
        }
    }
};

export const getBuyOrders = async () => {
    // console.log('sendOrder evoked orderDetails =', orderDetails)
    try {
        const response = await http.get('/order/buy');
        // console.log( 'api.js response = ', response)
        return response.data;
    } catch (error) {
        // Log the error for debugging
        console.error('Error in sendOrder:', error);

        // You can customize this part to handle different types of errors
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error('Response error:', error.response.data);
            throw new Error('Failed to send order: ' + error.response.data.message);
        } else if (error.request) {
            // The request was made but no response was received
            console.error('Request error:', error.request);
            throw new Error('No response from server');
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error('General error:', error.message);
            throw new Error('Error in setting up the request: ' + error.message);
        }
    }
};

export const sendToSell = async (values) => {
    // console.log('sendOrder evoked orderDetails =', orderDetails)
    try {
        const response = await http.post(`/sales/${values.catId}`, values);
        // console.log( 'api.js response = ', response)
        return response.data;
    } catch (error) {
        // Log the error for debugging
        console.error('Error in sendOrder:', error);

        // You can customize this part to handle different types of errors
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error('Response error:', error.response.data);
            throw new Error('Failed to send to sell: ' + error.response.data.message);
        } else if (error.request) {
            // The request was made but no response was received
            console.error('Request error:', error.request);
            throw new Error('No response from server');
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error('General error:', error.message);
            throw new Error('Error in setting up the request: ' + error.message);
        }
    }
};

// fetch('http://localhost:5000/send-email', {
//     method: 'POST',
//     headers: {
//         'Content-Type': 'application/json'
//     },
//     body: JSON.stringify(orderDetails)
// })
//     .then(response => response.text())
//     .then(data => {
//         console.log('Success:', data);
//         alert('Order placed successfully!');
//     })
//     .catch((error) => {
//         console.error('Error:', error);
//         alert('Failed to place order. Please try again.');
//     });