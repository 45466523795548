import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
// import Select from 'react-select';
//MY MODULES//
import { useAuth } from '../../../context/authContext.js';
import formatDateFromDB from '../../../utils/formatDateFromDB';
import FormInput from '../../../components/FormInput/FormInput';
import { fetchOneCat, putCat } from '../../../services/api';
// import axios from 'axios';

const Update = () => {

    const auth = useAuth() //auth.currentUser from /context/authContext.js
    const location = useLocation();
    const navigate = useNavigate()
    const catId = location.pathname.split("/")[2] //http://localhost:3001/post/1 - we take post number from URL address in browser

    const [values, setCat] = useState({
        cat_name: "",
        sex: "male",
        cat_birthdate: "",
        breed_id: "",
        users_id: "",
    });

    const onChange = (e) => {
        setCat((prev) => ({ ...prev, [e.target.name]: e.target.value, users_id: auth.currentUser.id }))
    };

    const inputs = [
        {
            id: 1,
            name: "description",
            type: "textarea",
            label: "Cat description",
            placeholder: "Cat description",
            onChange: onChange,
            maxLength: 100,
            required: false,
            inputId: "description"
        },
        {
            id: 2,
            name: "chip",
            type: "text",
            label: "Chip",
            placeholder: "Chip",
            errorMessage: "Use 3-100 letters or numbers",
            pattern: "^[А-Яа-яA-Za-z0-9 ]{3,100}$",
            required: false,
            maxLength: 100, // limit to 100 characters
            inputId: "chip"
        }
    ]


    //fetch Cat info from DB and store in state 'cat'
    useEffect(() => {
        const fetchData = async () => {
            try {
                const resData = await fetchOneCat(catId); // return already res.data
                // console.log('res.data from fetchOneCat', res.data)
                setCat(resData);
                const formattedDate = formatDateFromDB(resData.cat_birthdate)
                setCat((prev) => ({ ...prev, cat_birthdate: formattedDate }))

            } catch (err) { console.log(err) }
        }
        fetchData();
    }, [catId])

    const handleSubmit = async (e) => {
        e.preventDefault();
        // console.log(values);
        // console.log(breeder.id);
        // console.log("color before sinding", newColor)
        try {
            // Use Promise.all to send multiple requests concurrently
            await Promise.all([
                putCat(values), // This function updates the cat data
            ]);

            // Both requests completed successfully
            navigate(`/cats/${values.id}`);
        } catch (error) {
            console.log(error)
        }
    }

    const cancelEdit = () => {
        navigate(`/cats/${catId}`)
    }

    return (
        <div className="cat-edit-container">
            <div className="cat-edit">
                <form
                    onSubmit={handleSubmit}>
                    <h2>Edit Cat Profile - ID{values.id}</h2>
                    <p>Your cat is registered - you can edit only limited information.</p>
                    {inputs.map((input) => (
                        <FormInput
                            key={input.id}
                            {...input}
                            onChangeSelect={input.onChangeSelect}
                            options={input.options}
                            value={values[input.name] || ''}
                            onChange={onChange}
                            inputId={input.inputId} />
                    ))}
                    <button className='joinButton'>Send</button>
                    <button type='button' className='button-light' onClick={cancelEdit}>Cancel</button>
                </form>
            </div>
        </div>
    )
}

export default Update
