import React, { Fragment, useEffect } from 'react';
import { RouterProvider, createBrowserRouter, Outlet } from "react-router-dom";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import AddParentSearch from './components/AddParentSearch/AddParentSearch';
// import { YMInitializer } from 'react-yandex-metrika';
///// styles /////
import "./styles/global.scss";

///// pages //////
import Add from "./pages/Add/Add";
import AddCatWithColor from "./pages/AddCatWithColor/AddCatWithColor";
import AddPhoto from './pages/AddPhoto/AddPhoto';
import AddUserPhoto from './pages/AddUserPhoto/AddUserPhoto';
import Admin from "./pages/Admin/Admin";
import AllCats from "./pages/AllCats/AllCats";
import CatProfile from "./pages/CatProfile/CatProfile";
import UpdateShort from "./pages/CatProfile/UpdateShort/UpdateShort";
import Cattery from "./pages/Cattery/Cattery";
import CreateMetric from "./pages/CreateMetric/CreateMetric";
import CreatePedigree from "./pages/CreatePedigree/CreatePedigree";
import Edit from "./pages/Edit/Edit";
import EditCatProfile from "./pages/EditCatProfile/EditCatProfile";
import EditMetric from './pages/EditMetric/EditMetric';
import EditPedigree from "./pages/EditPedigree/EditPedigree";
import Join from "./pages/Join/Join";
import Login from "./pages/Login/Login";
import Main from './pages/Main/Main';
import Profile from "./pages/Profile";

import ErrorPage from "./pages/Error-page/error-page";
import Settings from './pages/Settings'
import Terms from './pages/Terms/Terms';
import PrivacyPolicy from './pages/PrivacyPolicy';

///// utils //////
import { RequireAuth } from "./context/RequireAuth";
import IndexRestore from './pages/IndexRestore';
// import ErrorPageNotAuth from './pages/ErrorPageNotAuth/ErrorPageNotAuth';
import { LanguageProvider } from '../src/context/langContext';
import Partners from './pages/Partners/Partners';
import Favorites from './pages/Favorites/Favorites';
import Market from './pages/Market/Market';
import CatBreeds from './pages/CatBreeds/CatBreeds';
import CatProfileSell from './pages/CatProfileSell/CatProfileSell';
import Order from './pages/Order/Order';
import OrderSent from './pages/Order/OrderSent';


const RootLayout = () => {
  return (
    <Fragment>
      <Header />
      <Outlet />
      <Footer />
    </Fragment>
  )
}


//Outlet - this is where the content form any naseted routes/pages is going to be output.
const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />, // take links from Outlet and give actual links
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <Main />
      },
      {
        path: "/partners",
        element: <Partners />
      },
      {
        path: "/allcats",
        element: <AllCats />
      },
      {
        path: "/login",
        element: <Login />
      },
      {
        path: "/join",
        element: <Join />
      },
      {
        path: "/restore",
        element: <IndexRestore />
      },
      {
        path: "/privacy",
        element: <PrivacyPolicy />
      },
      {
        path: "/terms",
        element: <Terms />
      },
      {
        path: "/market",
        element: <Market />
      },
      {
        path: "/catbreeds",
        element: <CatBreeds />
      },
      {
        path: "/cat-profile-sell/:id",
        element: <CatProfileSell />
      },
      {
        path: "/admin",
        element: <RequireAuth roles={[2]}><Admin /></RequireAuth>
      },
      {
        path: "/my",
        element: <RequireAuth roles={[1]}><Profile /></RequireAuth>
      },
      {
        path: "/edit",
        element: <RequireAuth roles={[1]}><Edit /></RequireAuth>
      },
      {
        path: "/settings",
        element: <RequireAuth roles={[1]}><Settings /></RequireAuth>
      },
      {
        path: "/add",
        element: <RequireAuth roles={[1]}><Add /></RequireAuth>,
      },
      {
        path: "/favorites",
        element: <RequireAuth roles={[1]}><Favorites /></RequireAuth>,
      },
      {
        path: "/addwithcolor",
        element: <RequireAuth roles={[1]}><AddCatWithColor /></RequireAuth>
      },
      {
        path: "/cattery",
        element: <RequireAuth roles={[1]}><Cattery /></RequireAuth>
      },
      {
        path: "/cats/:id/addphoto",
        element: <RequireAuth roles={[1]}><AddPhoto /></RequireAuth>
      },
      {
        path: "/users/:id/addphoto",
        element: <RequireAuth roles={[1]}><AddUserPhoto /></RequireAuth>
      },
      {
        path: "/cats/:id/addparent",
        element: <RequireAuth roles={[1]}><AddParentSearch /></RequireAuth>
      },
      {
        path: "/cats/:id/update",
        element: <RequireAuth roles={[1]}><EditCatProfile /></RequireAuth>
      },
      {
        path: "/cats/:id/updateshort",
        element: <RequireAuth roles={[1]}><UpdateShort /></RequireAuth>
      },
      {
        path: "/cats/:id",
        element: <RequireAuth roles={[1]}><CatProfile /></RequireAuth>
      },
      {
        path: "/cats/:id/new-metric",
        element: <RequireAuth roles={[1]}><CreateMetric /></RequireAuth>
      },
      {
        path: "/cats/:id/new-pedigree",
        element: <RequireAuth roles={[1]}><CreatePedigree /></RequireAuth>
      },
      {
        path: "/cats/:id/metric/:metric_id", //this is used when const {metric_id} = useParams() to access
        element: <RequireAuth roles={[1]}><EditMetric /></RequireAuth>
      },
      {
        path: "/cats/:id/pedigree/:pedigree_id", //this is used when const {metric_id} = useParams() to access
        element: <RequireAuth roles={[1]}><EditPedigree /></RequireAuth>
      },
      {
        path: "/order/:id", //this is used when const {metric_id} = useParams() to access
        element: <RequireAuth roles={[1]}><Order /></RequireAuth>
      },
      {
        path: "/order/sent", //this is used when const {metric_id} = useParams() to access
        element: <RequireAuth roles={[1]}><OrderSent /></RequireAuth>
      },
    ],
  },

]
)

//Oldfashioned:
// createRoutesFromElements(
//   <Route path="/" element={<Layout />}>
//     <Route index element={<Cats />} />
//     <Route path="login" element={<Login />} />
//     <Route path="join" element={<Join />} />
//     <Route path="cats" element={<Cats />} />
//     <Route path="add" element={<Add />} />
//       <Route path="/upload" element={<UploadFile />} />
//     <Route path="add2" element={<Add2 />} />
//     <Route path="/update/:id" element={<Update />} />
//   </Route>
// )

function App() {
  // Function to create or update a meta tag
  const updateMetaTag = (name, content) => {
    let metaTag = document.querySelector(`meta[name="${name}"]`);
    if (!metaTag) {
      metaTag = document.createElement('meta');
      metaTag.name = name;
      document.head.appendChild(metaTag);
    }
    metaTag.content = content;
  };
  useEffect(() => {
    let mainTitle;
    let descriptionContent;
    let keywordsContent;
    if (window.location.hostname.includes('catclub.ru') || window.location.hostname.includes('localhost')) {
      mainTitle = 'Cat Club - Клуб любителей кошек';
      descriptionContent = 'Клуб любителей кошек и маркетплейс по продаже котят в России';
      keywordsContent = 'коты, кошки, котята, заводчики, родословная, клуб кошек, WCF, TICA, коты на вязку, ассоциация заводчиков кошек, Россия';
    } else if (window.location.hostname.includes('catclub.pro')) {
      mainTitle = 'Cat Club - Community for Cat Lovers | Buy kiitens';
      descriptionContent = 'Join Cat Club to connect with cat breeders, manage pedigrees, and find kittens for sale in your area.';
      keywordsContent = 'cat, cats, kittens, breeders, pedigree, catclub, wcf, tica, malecat, cat breeders association, Canada';
    } else {
      mainTitle = 'Cat Club';
    }
    document.title = mainTitle;

    // Update meta tags
    updateMetaTag('description', descriptionContent);
    updateMetaTag('keywords', keywordsContent);

    // Check if meta description already exists
    // let metaTag = document.querySelector('meta[name="description"]');
    // if (!metaTag) {
    //   // Create meta description if it does not exist
    //   metaTag = document.createElement('meta');
    //   metaTag.name = "description";
    //   document.head.appendChild(metaTag);
    // }
    // // Set the content of meta description
    // metaTag.content = descriptionContent;
  }, []); // The empty array ensures this effect runs only once when the component mounts

  // const { setLanguage } = useLanguage();

  // // const [content, setContent] = useState({});
  // useEffect(() => {
  // const hostname = window.location.hostname;
  // console.log('hostname = ', hostname)
  // // let languageFile = 'en.json'; // default
  // if (hostname === 'catclub.ru' || 'www.catclub.ru' || 'localhost') {
  //   // languageFile = 'ru.json';
  //   // setLanguage('ru')
  // }

  // // fetch(`/languages/${languageFile}`)
  // //     .then(response => response.json())
  // //     .then(data => setContent(data));
  // }, [setLanguage]);

  return (
    <LanguageProvider>

      <div className="app">
        {/* <YMInitializer accounts={[95428596]} options={{webvisor: true, defer: true}}/> */}
        <div className="container">
          <RouterProvider router={router} />
        </div>
      </div>

    </LanguageProvider>
  )
}

export default App
