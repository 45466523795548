
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import CatCard from '../../../components/CatCard/CatCard'
import "../parents.scss";
import useParents from '../useParents';
import { deleteFather, deleteMother } from '../../../services/api';
import { useAuth } from '../../../context/authContext';
import { CatContext } from '../../../context/CatContext';

const ParentsTab = () => {
    // console.log("ParentsTab | catProfileValue =", catProfileValue)
    // const catId = useParams().id;
    // console.log('ParentsTab, catId = ', catId)
    const auth = useAuth();
    const currentUserId = auth.currentUser.id;
    const parents = useParents();
    const { data} = useContext(CatContext);
    // console.log('ParentsTab | data', data);
    const cat = data;
    const catId = cat.id

    // console.log('parents = ', parents);

    const [mother, setMother] = useState({});
    const [father, setFather] = useState({});

    useEffect(() => {

        if (parents) {
            // console.log('ParentsTab useEffect parents =', parents)
            setMother(parents.mother);
            setFather(parents.father);
        }
    }, [parents, catId])

    const handleDeleteMother = async () => {
        try {
            await deleteMother(catId, currentUserId)
            setMother({})
            window.location.reload() //to force the document to be fetced from the web server again.
            // Without it, when we delete a cat - it deletes in DB, but still in frontend.
            // In future it better to do it using REDUX or some other managment tools
        } catch (error) {
            // Expected (404: not found, 400: bad request) - CLIENT ERRORS
            // - Display a specific error message
            if (error.response && error.response.status === 404) {
                alert('Expected error occured'); // we don't need to log (console.log) errors which come from client
            } else {
                console.log('Error', error);
            }

        }
    }

    const handleDeleteFather = async () => {
        try {
            await deleteFather(catId, currentUserId)
            setFather({})
            window.location.reload()
        } catch (error) {
            if (error.response && error.response.status === 404) {
                alert('Expected error occured');
            } else {
                console.log('Error', error);
            }
        }
    }

    // if (!mother || !father) {
    //     return <div>Loading...</div>;
    // }

    return (
        <div className='cat-profile__parents'>
            <div className='cat-profile__parents-item'>
                {mother?.id > 0 ? (
                    <Fragment>
                        <h3 className='cat-profile__parents-header'>Mother</h3>
                        <CatCard
                            sex={mother.sex}
                            cat_birthdate={mother.cat_birthdate}
                            cover={mother.cat_ava_url}
                            cat_name={mother.cat_name}
                            id={mother.id}
                            cat_name_cattery_prefix={mother.cat_name_cattery_prefix}
                            cat_name_suffix={mother.cat_name_suffix}
                            registered={mother.registered}
                        />
                        <div className='cat-profile__buttons'>
                            <button className="delete" onClick={handleDeleteMother}>Delete</button>
                            <Link to={`/cats/${catId}/addparent?p=female`}><button className="button-light">Change</button></Link>
                        </div>
                    </Fragment>
                ) : (
                    <Link to={`/cats/${catId}/addparent?p=female`}><button className="button-light">SELECT MOTHER</button></Link>
                )
                }
            </div>
            <div className='cat-profile__parents-item'>
                {father?.id > 0 ? (
                    <Fragment>
                        <h3 className='cat-profile__parents-header'>Father</h3>
                        <CatCard
                            sex={father.sex}
                            cat_birthdate={father.cat_birthdate}
                            cover={father.cat_ava_url}
                            cat_name={father.cat_name}
                            id={father.id}
                            cat_name_cattery_prefix={father.cat_name_cattery_prefix}
                            cat_name_suffix={father.cat_name_suffix}
                            registered={father.registered}
                        />
                        <div className='cat-profile__buttons'>
                            <button className="delete" onClick={handleDeleteFather}>Delete</button>
                            <Link to={`/cats/${catId}/addparent?p=male`}><button className="button-light">Change</button></Link>
                        </div>

                    </Fragment>
                ) : (
                    <Link to={`/cats/${catId}/addparent?p=male`}><button className="button-light">SELECT FATHER</button></Link>
                )
                }
            </div>
        </div>
    )
}

export default ParentsTab



