import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import FormInput from '../../components/FormInput/FormInput';
import "./editMetric.scss"
import { useAuth } from '../../context/authContext';
import { formatValuesForSelect } from '../../utils/formatValuesForSelect';
// import Select from 'react-select';
import { UploadFile } from '../../components/UploadFile/UploadFile'
import { HandleUpload } from '../../components/UploadFile/HandleUpload';
import { fetchCatSys, fetchOneMetric, putMetric } from '../../services/api';

///// Component EditMetric can add a new metric or edit an existing metric /////
/////////// ----- without uploading a file - can not be submitted - needs to be refactored latter.

const EditMetric = () => {

  const navigate = useNavigate();
  const auth = useAuth();
  const location = useLocation();
  const currentUserId = auth.currentUser.id;
  // const catId = new URLSearchParams(location.search).get('id');
  const catId = location.pathname.split("/")[2] //http://localhost:3001/post/1 - we take a number from URL address in browser
  const { metric_id: metricId } = useParams() //ranaming here
  // console.log('metricId', metricId); //'metirc_id' nameing was establish in APP.js
  // We need to get metric values. We get from cat table metric_id and JOIN all metric data.
  // const [isFilePath, setIsFilePath] = useState(false);

  const [values, setValues] = useState(
    {
      id: null,
      metric_num: '',
      issued_by: '',
      metric_img_url: '',
      cat_systems_id: '',
      catId
    }
  );
  const [selectError, setSelectError] = useState({
    fileError: "",
    systemError: ""
  });

  const BASE_URL = process.env.REACT_APP_API_URL; //need only for UploadFile - latter maybe better to refactor


  // const [isCreating, setIsCreating] = useState(true); // determine if we're creating a new entity or updating an existing one
  const [currentSystem, setCurrentSystem] = useState('')
  const [catSystems, setCatSystems] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [filePa, setFilePa] = useState(null);

  const inputs = [
    {
      id: 1,
      name: "metric_num",
      type: "text",
      placeholder: "Metric number",
      errorMessage: "3-50 lettters or numbers",
      label: "Metric number*",
      pattern: "^[A-Za-z0-9 ]{3,50}$", //This pattern allows for any uppercase or lowercase letters, digits, and a single space, with a minimum length of 3 and a maximum length of 50
      required: true,
      maxLength: 50 // limit to 100 characters
      // className:"joinInput"
    },
    {
      id: 2,
      name: "issued_by",
      type: "text",
      placeholder: "Issued by",
      label: "Metric was issued by (Club)",
      maxLength: 50, // limit to 100 characters
      errorMessage: "3-50 lettters or numbers",
      pattern: "^[А-Яа-яA-Za-z0-9 ]{3,50}$", //This pattern allows for any uppercase or lowercase letters, digits, and a single space, with a minimum length of 3 and a maximum length of 50
      required: false,
      // className:"joinInput"
    },
    {
      id: 3,
      name: "cat_systems_id",
      type: "select", // Change the type to 'textarea'
      placeholder: "System issued a metric",
      errorMessage: "System can't be empty",
      label: "Metric was issued according system*",
      required: true,
      // maxLength: 100 // limit to 100 characters
    },
  ]

  useEffect(() => {
    const fetchDataOneMetric = async () => {
      if (metricId) {
        try {
          //    SELECT metrics.id, metrics.metric_num, metrics.issued_by, metrics.metric_img_url, metrics.cat_systems_id, cat_systems.abb AS abb
          const resData = await fetchOneMetric(catId, currentUserId)
          // console.log('res.data', res.data)
          setValues(resData);
        } catch (error) { console.log(error); }
      }
    }
    fetchDataOneMetric();
    // if (values.id) {fetchOneMetric(auth.currentUser); setTitleNew('- new');}
  }, [currentUserId, catId, metricId]);

  useEffect(() => {
    const fetchCatSystems = async () => {
      // console.log('currentUser', currentUser)
      try {
        const res = await fetchCatSys();
        const dataforSelect = formatValuesForSelect(res, 'name', 'abb');
        setCatSystems(dataforSelect); //better like this setCatSystems(dataforSelect) - without callback arraw function inside
        // console.log('catSystem', dataforSelect)
        if (metricId) {
          //Show the current metric's system (from DB)
          // console.log('values.cat_systems_id = ', values.cat_systems_id)
          const filtered = dataforSelect.filter(sys => sys.value === values.cat_systems_id)
          // console.log('filtered', filtered)
          setCurrentSystem(filtered);
        }
      } catch (error) {
        console.log(error);
      }
    }
    fetchCatSystems()
  }, [values.cat_systems_id, metricId])

  // Everytime file is uploaded (state in filePa) useEffect is evoked
  useEffect(() => {
    if (filePa) {
      final(filePa, values);
      setSelectError({ fileError: "" });
    }
    else {
      setSelectError({ fileError: "Upload a photo" });
    }
  }, [filePa]);

  const handleChangeFile = (file) => {
    setSelectedFile(file);
    setSelectError({ fileError: "" });
  }

  //UPLOAD FILE to SERVER to folers uploads/user_id/file-name. 'metric' here is the prefix which isert into file name before saving it.
  async function uploadCattery() {
    // console.log('uploadCattery evoked')
    let fileP = await HandleUpload(selectedFile, `${BASE_URL}/upload`, auth.currentUser.id, 'metric', catId); //'metric' - is a prefix which is added to file name befroe saving (backend)
    setFilePa(fileP);
    setValues((prev) => ({ ...prev, metric_img_url: fileP.filePath }))
  }

  const final = async () => {
    try {
      const response = await putMetric(metricId, values);
      console.log('New entity created:', response.data);
      navigate(`/cats/${catId}`);
    }
    catch (err) { console.log('Error submiting form:', err) }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (currentSystem) {
      if (selectedFile || values.metric_img_url) {
        setSelectError({ fileError: "" });
        uploadCattery();
      } else {
        final();
        setSelectError({ fileError: "Upload a photo" });
      }
    } else {
      setSelectError({ systemError: "Chose a system" });
    }
  };

  const onChange = (e) => {
    setValues((prev) => ({ ...prev, [e.target.name]: e.target.value }))
  };

  const onChangeSystem = (selectedOption) => {
    setCurrentSystem(() => selectedOption);
    setValues((prev) => ({ ...prev, cat_systems_id: selectedOption.value }))
  }

  const cancelEdit = () => {
    navigate(`/cats/${catId}`)
  }

  // const metricImg = `../../${values.metric_img_url}`;

  return (
    <div className="edit-metric">
      <form className="cattery-form" onSubmit={handleSubmit}>
        <h1>Metric </h1>
        {values && (
          inputs.map((input) => (
            //valueSelect send to FormInput value as props
            <FormInput key={input.id} valueSelect={currentSystem} options={catSystems} {...input} onChangeSelect={onChangeSystem} value={values[input.name] || ''} onChange={onChange} />))
        )}
        {/* it's rendered if we don't have any metric in our values */}
        {/* {values && !values[0] && (
          inputs.map((input) => (
            <FormInput key={input.id} options={catSystems} {...input} onChangeSelect={onChangeSystem} value={values[input.name] || ''} onChange={onChange} />))
          )} */}
        {/* 
        <div className="cattery-selectInput">
        <label className="selectInput__label">Your cattery system (on your certificate)*</label>
        <Select className="selectInput__input" options={catSystems} onChange={onChangeSystem} value={currentSystem} />
      </div> */}
        {/* {console.log('values from EditMEtric from return', values)}*/}
        {/* DOESN'T WORK - I don't see IMAGE - don't know why */}
        {values.metric_img_url &&
          <figure className='metric-img'>
            <div>
              <figcaption>Current image in database*</figcaption>
              <img src={values.metric_img_url} alt="Cat-metric" />
            </div>
          </figure>
        }
        {selectError.systemError && <div className='messageError'>{selectError.systemError}</div>}
        <UploadFile selectedFile={selectedFile} onChangeFile={handleChangeFile} />
        <button type='submit' className='joinButton'>Send</button>
        <button type='button' className='button-light' onClick={cancelEdit}>Cancel</button>
        {selectError.fileError && <div className='messageError'>{selectError.fileError}</div>}
        {/* <span>Do you have an account? <Link to="/login">Login</Link></span> */}
      </form>
    </div>
  )

}

export default EditMetric
