// import PhotoTab from "./PedigreeTab/PhotoTab";
// import { useParams } from "react-router-dom";
import { useTranslations } from "../../hooks/useTranslations";
import TabView from "../../components/ui/TabView/TabView";
import PedigreeTab from "./PedigreeTab/PedigreeTab";
import DocumentsTab from "./PedigreeTab/DocumentsTab";
import ParentsTab from "./PedigreeTab/ParentsTab";
import SellingTab from "./SellingTab/SellingTab";
import OtherTab from "./OtherTab/OtherTab";
import './catProfileLow.scss'
import { useContext } from "react";
import { CatContext } from "../../context/CatContext";

const CatProfileLow = ({ catProfileValue }) => {
    // console.log("CatProfileLow | catProfileValue ", catProfileValue)
    // const catId = useParams().id;
    const { translations } = useTranslations();
    const { data } = useContext(CatContext);
    // console.log()

    return (
        <TabView title={""} tabs={[
            { name: translations.parents, content: <ParentsTab /> },
            { name: translations.pedigree, content: <PedigreeTab catProfileValue={catProfileValue} /> },
            { name: translations.docs, content: <DocumentsTab catProfileValue={catProfileValue} /> },
            // { name: "Photos", content: <PhotoTab text={'Some photo should be here'} /> },
            { name: translations.cat_profile.other, content: <OtherTab catProfileValue={catProfileValue} /> },
            { name: translations.cat_profile.selling, content: <SellingTab  /> },
        ]} />
    )
}
export default CatProfileLow;