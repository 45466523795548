import React, { useContext, useEffect, useState } from 'react'
import "./catprofile.scss";
import { useLocation, useNavigate } from 'react-router-dom';
// import formatDate from '../../utils/formatDate';
import { useAuth } from '../../context/authContext';
import CatProfileLow from './CatProfileLow.jsx';
import {
    deleteCat,
    fetchApplications,
    // fetchOneCatAndColor,
    fetchOneMetric,
    fetchOnePedigree,
    fetchPrevOwnerFromCatChangeOwner,
    postCatChangeOwnerFromPrev,
    putUserFromPrev
} from '../../services/api';
// import Parents from './Parents';
import CatProfileUp from '../CatProfile/CatProfileUp/CatProfileUp.jsx';
import { CatContext, CatContextProvider } from '../../context/CatContext.js';
import { LanguageContext } from '../../context/langContext.js';

// export const CatProfileContext = createContext();

const CatProfile = () => {
    // const urlParts = window.location.pathname.split('/');
    // console.log('urlParts = ', urlParts);
    // const catId = urlParts[urlParts.length - 1];

    // Only this aproach (location) let catProfile reload when click parant's catcard on CatProfileLow //
    const location = useLocation();
    const urlParts = location.pathname.split('/');
    const catId = urlParts[urlParts.length - 1];
    // debugger; // Execution will pause here, and you can inspect `urlParts` and `catId`
    // console.log('CatProfile | catId', catId)

    // useEffect(() => {
    //     console.log(' CatProfile useEffect| catId', catId);
    //     // Any other logic you need when catId changes
    // }, [catId]);
    return (
        <CatContextProvider catId={catId} key={catId}>
            <CatProfileChild />
        </CatContextProvider>
    );
}

const CatProfileChild = () => {
    const { data, loading, error } = useContext(CatContext);
    // console.log('CatProfile | data', data);
    const cat = data;
    const { translations, language } = useContext(LanguageContext)

    const navigate = useNavigate();
    // const location = useLocation();
    const auth = useAuth();
    const currentUserId = auth.currentUser.id;
    // const catId = location.pathname.split("/")[2] //http://localhost:3001/post/1 - we take post number from URL address in browser
    // const catId = cat?.id;
    // console.log('catProfileChild | cat?.id = ', cat?.id)

    const [state, setState] = useState({
        metric: {},
        pedigree: {},
        applicationStatus: {},
        prevOwner: [],
    });

    useEffect(() => {
        const fetchCatRelatedData = async () => {

            try {
                // const resDataOneCatAndColor = await fetchOneCatAndColor(catId);
                // setCat(resDataOneCatAndColor)
                // console.log('cat = ', cat)

                if (cat?.metrics_id) {
                    const resDataOneMetric = await fetchOneMetric(cat?.id, currentUserId)
                    // console.log('resDataOneMetric = ', resDataOneMetric)
                    if (resDataOneMetric) setState((prev) => ({ ...prev, metric: resDataOneMetric }));
                }
                if (cat?.pedigrees_id) {
                    const resDataOnePedigree = await fetchOnePedigree(cat?.id, currentUserId)
                    if (resDataOnePedigree) setState((prev) => ({ ...prev, pedigree: resDataOnePedigree }));
                }

                const resDataApplication = await fetchApplications(cat?.id);
                //fetch previous owner (the last one!, because we have date in created_at entity) from DB Table cat_change_owner
                const resDataPrevOwner = await fetchPrevOwnerFromCatChangeOwner(cat?.id); //SELECT prev_owner_id FROM cat_change_owner 
                const updatedState = {
                    applicationStatus: {},
                    prevOwner: resDataPrevOwner || [], //[{prev_owner_id}] - we are sending to this
                    // birthdate: formatDate(cat.cat_birthdate),
                };

                if (resDataApplication) updatedState.applicationStatus = resDataApplication;

                setState(prevState => ({ ...prevState, ...updatedState }));

            } catch (error) { console.log(error) }
        }
        fetchCatRelatedData();
    }, [cat, currentUserId])

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;

    const handleDelete = async () => {
        console.log('delete clicked')
        try {
            await deleteCat(cat.id)
            navigate('/my')
            // window.location.reload() //to force the document to be fetced from the web server again.
            // Without it, when we delete a cat - it deletes in DB, but still in frontend.In future it better to do it using REDUX or some other managment tools
        } catch (ex) {
            // Expected (404: not found, 400: bad request) - CLIENT ERRORS
            // - Display a specific error message
            if (ex.response && ex.response.status === 404)
                alert('Expected error occured'); // we don't need to log (console.log) errors which come from client
        }
    }
    // Moving cat from my page to prevOwner page
    const handleMove = async () => {
        try {
            const dataToSend = { catId: cat.id, prevOwner: state.prevOwner[0]?.prev_owner_id }
            // console.log('dataToSendForPut', dataToSend)
            const [response1, response2] = await Promise.all([
                //insert in CAT table a prevOwner 
                putUserFromPrev(dataToSend),
                postCatChangeOwnerFromPrev(dataToSend) //transaction and prevOwner (which was fetched before) in a DB Table cat_change_owner
            ]);

            console.log(response1.data, response2.data);
            navigate('/my')
        } catch (error) {
            console.error('Failed to move cat:', error);
        }
    }

    const catProfileValue = {
        ...state,
        handleDelete,
        handleMove,
    }
    // console.log('CatProfile.jsx | catProfileValue = ', catProfileValue)

    return (
        // <CatProfileContext.Provider value={catProfileValue}>
        <div className="cat-profile__container" >
            <h2 className='cat-profile__header'>{translations.cat_profile.cat_profile} </h2>
            <CatProfileUp catProfileValue={catProfileValue} />
            <CatProfileLow catProfileValue={catProfileValue} />
        </div>
        // </CatProfileContext.Provider>
    )
}

export default CatProfile

//key={catId} urge components rerender